import { Cluster } from "@solana/web3.js";
import { MMKV } from "react-native-mmkv";

const isClient = typeof window != "undefined";
const walletStorage = new MMKV();
const DEVNET_CLUSTER = "devnet";
const MAINNET_CLUSTER = "mainnet-beta";
const DEFAULT_CLUSTER: Cluster = __DEV__
  ? DEVNET_CLUSTER
  : (isClient && (walletStorage.getString("cluster") as Cluster)) ||
    MAINNET_CLUSTER;

export { DEVNET_CLUSTER, MAINNET_CLUSTER, DEFAULT_CLUSTER };
