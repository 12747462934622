import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

function SvgPolygonScan(props: SvgProps) {
  return (
    <Svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M18.111 7.993a1.543 1.543 0 00-1.505 0l-3.454 2.003-2.347 1.307-3.454 2.003a1.545 1.545 0 01-1.506 0L3.1 11.738a1.498 1.498 0 01-.752-1.263V7.383A1.408 1.408 0 013.1 6.12L5.8 4.596a1.544 1.544 0 011.506 0l2.7 1.524a1.498 1.498 0 01.753 1.263v2.004l2.347-1.35V6.032a1.409 1.409 0 00-.752-1.263L7.35 1.896a1.544 1.544 0 00-1.506 0L.752 4.77A1.408 1.408 0 000 6.033v5.792a1.408 1.408 0 00.752 1.264l5.093 2.874a1.545 1.545 0 001.505 0l3.454-1.96 2.347-1.35 3.454-1.96a1.542 1.542 0 011.506 0l2.701 1.524a1.499 1.499 0 01.752 1.263v3.093a1.409 1.409 0 01-.752 1.263l-2.701 1.568a1.544 1.544 0 01-1.506 0l-2.701-1.525a1.499 1.499 0 01-.752-1.263v-2.003l-2.347 1.35v2.003a1.408 1.408 0 00.752 1.263l5.093 2.875a1.545 1.545 0 001.505 0l5.093-2.875A1.498 1.498 0 0024 17.966v-5.792a1.408 1.408 0 00-.752-1.263L18.11 7.993z"
        fill={props.color}
      />
    </Svg>
  );
}

export default SvgPolygonScan;
