import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgShopping = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      fillRule="evenodd"
      d="M12 3.364c-1.85 0-3.348 1.322-3.348 2.954v2.046h6.697V6.318c0-1.632-1.5-2.954-3.349-2.954ZM12 2c2.703 0 4.894 1.933 4.894 4.318v2.046h1.803c.996 0 1.803.712 1.803 1.59v10c0 .88-.807 1.591-1.803 1.591H5.303c-.996 0-1.803-.712-1.803-1.59v-10c0-.88.807-1.591 1.803-1.591h1.803V6.318C7.106 3.933 9.297 2 12 2ZM5.303 9.727c-.142 0-.258.102-.258.228v10c0 .125.116.227.258.227h13.394c.142 0 .258-.102.258-.227v-10c0-.126-.116-.228-.258-.228H5.303Zm11.655 2.656c0 .377-.346.682-.772.682-.427 0-.773-.305-.773-.681 0-.377.346-.682.773-.682.426 0 .772.305.772.681Zm-9.03.682c.426 0 .772-.305.772-.681 0-.377-.346-.682-.773-.682-.427 0-.773.305-.773.681 0 .377.346.682.773.682Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgShopping;
