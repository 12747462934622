import * as React from "react";

import Svg, { SvgProps, Path, Circle, G } from "react-native-svg";

const SvgShoppingCart = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 90 90" fill="none" {...props}>
    <G mask="url(#ShoppingCart_svg__a)">
      <Path
        d="M73.713 65.44H27.689c-3.566 0-6.377-2.578-6.686-6.13-.21-2.426.807-4.605 2.592-5.939L16.381 21.07a3 3 0 0 1 2.927-3.654H87a3 3 0 0 1 2.832 3.988l-7.798 22.344a9.153 9.153 0 0 1-7.063 5.999l-47.389 8.281-.032.005c-.228.04-.623.126-.568.759.056.648.48.648.708.648h46.024a3 3 0 1 1-.001 6z"
        fill={props.color}
      />
      <Path
        d="M19.306 23.417a3.001 3.001 0 0 1-2.925-2.347l-1.375-6.155a5.356 5.356 0 0 0-5.258-4.212H3a3 3 0 1 1 0-6h6.749c5.372 0 9.942 3.662 11.113 8.904l1.375 6.155a3 3 0 0 1-2.931 3.655z"
        fill={props.color}
      />
      <Circle cx="28.25" cy="75.8" r="6.5" fill={props.color} />
      <Circle cx="68.29" cy="75.8" r="6.5" fill={props.color} />
    </G>
  </Svg>
);

export default SvgShoppingCart;
