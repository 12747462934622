import { withTiming } from "react-native-reanimated";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const MODAL_ITEM_STYLE =
  "mx-2 mb-2 justify-start items-center rounded bg-gray-100 p-4 hover:bg-gray-100 flex-row";

const DEFAULT_DRAWER_WIDTH = 400;

const useNavigationStore = createWithEqualityFn(
  (set: Function, get: Function) => ({
    drawerWidth: 0,
    setDrawerWidth: (drawerWidth: number) => {
      set({ drawerWidth });
    },
    mobileDrawerOffet: null,
    setMobileDrawerOffet: (mobileDrawerOffet: any) => {
      set({ mobileDrawerOffet });
    },
    isSidebarOpened: false,
    setIsSidebarOpened: (isSidebarOpened: boolean) => {
      set({ isSidebarOpened });
    },
    toggleMobileDrawer: () => {
      const { mobileDrawerOffet, drawerWidth } = get();
      const newOffset = mobileDrawerOffet.value == 0 ? -drawerWidth : 0;
      mobileDrawerOffet.value = withTiming(newOffset);
      set({ isSidebarOpened: newOffset == 0 });
    },
    closeMobileDrawer: () => {
      const { mobileDrawerOffet } = get();
      if (!mobileDrawerOffet) {
        return;
      }
      mobileDrawerOffet.value = withTiming(-DEFAULT_DRAWER_WIDTH);
      set({ isSidebarOpened: false });
    },
    sheet: null,
    setSheet: (sheet: any) => {
      set({ sheet });
    },
    hideSheet: () => {
      set({ sheet: null });
    },
    modal: null,
    setModal: (modal: any) => {
      set({ modal });
    },
    hideModal: () => {
      set({ modal: null });
    },
  }),
  shallow
);

export { useNavigationStore, MODAL_ITEM_STYLE, DEFAULT_DRAWER_WIDTH };
