import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgRaffle = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.181 10c.614.006.859.315.736.924-.058.429-.121.856-.19 1.282.462.287.938.553 1.43.798.298.237.361.524.189.861a.648.648 0 0 1-.316.232c-.542.1-1.081.205-1.618.315l-.336 1.68c-.283.404-.62.46-1.009.168-.278-.494-.552-.99-.82-1.491-.6.068-1.203.124-1.807.168-.45-.215-.555-.545-.315-.987.394-.374.766-.766 1.114-1.177-.277-.55-.53-1.11-.757-1.68.079-.425.331-.614.757-.568.525.217 1.036.462 1.534.736.398-.356.783-.727 1.156-1.114.081-.061.165-.11.252-.147Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.229 2.673a.656.656 0 0 0 .336-.336c.254-.35.576-.428.967-.231l1.828 1.828a.795.795 0 0 1 .084.546c-.185.216-.34.454-.462.714-.25.977.107 1.615 1.072 1.912.36.05.697-.02 1.008-.21a.826.826 0 0 1 .799-.336c.073.02.143.047.21.084L21.9 8.47a.721.721 0 0 1-.042.799 1786.108 1786.108 0 0 0-12.63 12.604.68.68 0 0 1-.756.021l-1.829-1.827a.74.74 0 0 1-.084-.547 5.79 5.79 0 0 1 .379-.546c.35-.782.175-1.42-.526-1.912a1.468 1.468 0 0 0-1.219-.083 9.537 9.537 0 0 0-.714.462.796.796 0 0 1-.547-.084L2.103 15.53a.709.709 0 0 1 .042-.798A4903.61 4903.61 0 0 1 14.23 2.673Zm.883.798c.318.256.62.536.903.84-.105.302-.203.61-.294.925-.159 1.314.352 2.273 1.534 2.878.814.291 1.605.242 2.375-.147.32.272.622.566.903.882a314.632 314.632 0 0 1-3.047 3.068l-5.4-5.4a309.198 309.198 0 0 0 3.025-3.046Zm-3.741 14.58-.294.252a11.88 11.88 0 0 0-.841.84s-.154.126-.21.21l-.336.336s-.21.183-.295.295c0 0-.371.382-.588.546a7.766 7.766 0 0 1-.82-.84c.114-.313.212-.635.295-.967.126-1.595-.582-2.597-2.123-3.004a2.653 2.653 0 0 0-1.828.357c-.315-.3-.616-.615-.904-.945 2.603-2.56 5.202-5.144 7.797-7.752l5.4 5.399a932.477 932.477 0 0 0-5.253 5.273Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgRaffle;
