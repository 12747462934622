import ar from "./ar.json";
import en from "./en.json";

const locales: any = {
  ar,
  en,
};

const localesData: any = {
  ar: { flag: "🇦🇪", title: "العربية", direction: "rtl" },
  en: { flag: "🇬🇧", title: "English", direction: "ltr" },
};

export { locales, localesData };
