import { APP_SERVER } from "../constants";

const publicResource = (resouce: string = "") => {
  return `${APP_SERVER}/${resouce}`;
};

const formateDateTime = (datetime: string = "") => {
  const [date, time] = datetime.split("T");

  return `${date} ${(time || "").substring(0, 5)}`;
};

function formatCompactNumber(number: number) {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1) + "T";
  }
}

const createUUID = () => {
  // http://www.ietf.org/rfc/rfc4122.txt
  let s: any = [];
  const hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

const currentUnixTimeStamp = (ms = 1000) => {
  return Math.floor(new Date().getTime() / ms);
};

const sleep = (seconds: number = 1) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const intervalRecord = new Set<number>();
const idToIntervalRecord = new Map<string, number>();
const retry = async <T,>(
  fetcher: () => Promise<any>,
  options?: {
    id?: string;
    retryCount?: number;
    interval?: number;
    errorMsg?: string;
    sleepTime?: number;
    onError?: (msg?: string) => void;
  }
): Promise<T> => {
  const {
    retryCount = 10,
    interval = 1000,
    errorMsg = "request failed",
    sleepTime,
    onError,
  } = options || {};
  let retryCounter = 0;
  return new Promise(async (resolve, reject) => {
    if (sleepTime !== undefined) await sleep(sleepTime);
    fetcher()
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        const intervalId = window.setInterval(async () => {
          retryCounter++;
          if (retryCounter > retryCount) {
            onError?.(errorMsg);
            clearInterval(intervalId);
            intervalRecord.delete(intervalId);
            reject(new Error(errorMsg));
          }
          try {
            const res = await fetcher();
            clearInterval(intervalId);
            intervalRecord.delete(intervalId);
            resolve(res);
          } catch (e: any) {
            if (e.message === "tx failed") {
              onError?.(e.message);
              clearInterval(intervalId);
              intervalRecord.delete(intervalId);
              reject(new Error("tx failed"));
            }
          }
        }, interval);
        intervalRecord.add(intervalId);
        if (options?.id) idToIntervalRecord.set(options.id, intervalId);
      });
  });
};

export {
  publicResource,
  formateDateTime,
  formatCompactNumber,
  createUUID,
  currentUnixTimeStamp,
  sleep,
  retry,
  idToIntervalRecord,
};
