// Array.prototype.fill = function (howMany = 0, value = null) {
//   while (howMany--) {
//     this.push(value);
//   }
// };

// Array.prototype.bachIn = function (size) {
//   var group = [];
//   for (var i = 0, j = 0; i < this.length; i++) {
//     if (i >= size && i % size === 0) j++;
//     group[j] = group[j] || [];
//     group[j].push(this[i]);
//   }
//   return group;
// };

// Array.prototype.sample = function () {
//   return this[Math.floor(Math.random() * this.length)];
// };

// Array.prototype.sortByArray = function (arr, key = "id") {
//   return this.sort(function (a, b) {
//     return arr.indexOf(a[key]) - arr.indexOf(b[key]);
//   });
// };

Array.prototype.compact = function () {
  let data = [];
  this.forEach((el) => {
    if (el) {
      data.push(el);
    }
  });

  return data;
};

// Array.prototype.sortNums = function () {
//   return this.sort((a, b) => {
//     return parseFloat(a) - parseFloat(b);
//   });
// };

Array.prototype.isLastIndex = function (index) {
  return this.length - 1 == index;
};

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.take = function (size) {
  return this.slice(0, size);
};

Array.prototype.any = function () {
  return this.length !== 0;
};

Array.prototype.empty = function () {
  return this.length === 0;
};

Array.prototype.chunker = function (chunkSize) {
  if (!this.length) {
    return [];
  }

  return this.reduce(function (previous, current) {
    var chunk;
    if (
      previous.length === 0 ||
      previous[previous.length - 1].length === chunkSize
    ) {
      chunk = []; // 1
      previous.push(chunk); // 2
    } else {
      chunk = previous[previous.length - 1]; // 3
    }
    chunk.push(current); // 4
    return previous; // 5
  }, []); // 6
};

// Array.prototype.include = function (value) {
//   return this.indexOf(value) !== -1;
// };

Array.prototype.uniq = function () {
  return [...new Set(this)];
};

Array.prototype.without = function (value) {
  let data = [...this];
  const index = data.indexOf(value);
  if (index !== -1) {
    data.splice(index, 1);
  }

  return data;
};

Array.prototype.withoutIndex = function (index) {
  if (index < 0) {
    return this;
  }
  let data = [...this];
  data.splice(index, 1);

  return data;
};

Array.prototype.sum = function () {
  return this.reduce(function (a, b) {
    return a + b;
  });
};

Array.prototype.mean = function () {
  return this.sum() / (this.length * 1.0);
};

// Array.prototype.average = function () {
//   return this.mean();
// };

// Array.prototype.mode = function () {
//   if (this.length == 0) {
//     return null;
//   }

//   let modeMap = {};
//   let maxEl = this[0],
//     maxCount = 1;
//   for (let i = 0; i < this.length; i++) {
//     let el = this[i];
//     if (modeMap[el] == null) modeMap[el] = 1;
//     else modeMap[el]++;
//     if (modeMap[el] > maxCount) {
//       maxEl = el;
//       maxCount = modeMap[el];
//     }
//   }

//   return maxEl;
// };

// Array.prototype.sortNumbers = function () {
//   return this.sort((a, b) => b - a);
// };

String.prototype.slugify = function () {
  return String(this)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
};

// String.prototype.in = function (array) {
//   const str = this.split("").join("");
//   return array.indexOf(str) !== -1;
// };

// String.prototype.to_i = function () {
//   return parseInt(this);
// };

// String.prototype.to_f = function () {
//   return parseFloat(this);
// };

// String.prototype.capitalize = function () {
//   return this.charAt(0).toUpperCase() + this.slice(1);
// };

export {};
