import { Platform } from "react-native";

import axios, { AxiosError } from "axios";
import { formatDistanceToNowStrict } from "date-fns";
import { ResizeMode } from "expo-av";

import { ResizeMode as ImageResizeMode } from "@showtime-xyz/universal.image";

import { removeMd } from "app/lib/remove-markdown";

import { Profile } from "./types";

export const removeTags = (text: string) => {
  return removeMd(text.replace(/<(?:.|\n)*?>/gm, ""));
};

const snakeToCamel = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const camelCaseObject = (obj: any = {}) => {
  if (!obj) {
    return obj;
  }

  let newObj: any = {};
  Object.keys(obj).forEach((d: string) => {
    let value = obj[d];
    if (Array.isArray(value)) {
      value = value.map((v) => camelCaseObject(v));
    } else if (typeof value == "object") {
      value = camelCaseObject(value);
    }
    newObj[snakeToCamel(d)] = value;
  });

  return newObj;
};

export const hookFormValue = (value: string, defaultValue: string = "") => {
  return value == undefined ? defaultValue : value || "";
};

// Format big numbers
export function formatNumber(number: number) {
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}m`;
  } else if (number > 1000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else {
    return number;
  }
}
export function formatToUSNumber(number: number) {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}m`;
  } else if (number >= 10000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else {
    const str = number.toString();
    const reg =
      str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg, "$1,");
  }
}

export const DROPDOWN_LIGHT_SHADOW =
  "0px 12px 16px rgba(0, 0, 0, 0.1), 0px 16px 48px rgba(0, 0, 0, 0.1)";
export const DROPDOWN_DRAK_SHADOW =
  "0px 0px 2px rgba(255, 255, 255, 0.5), 0px 16px 48px rgba(255, 255, 255, 0.2)";

export const getDropdownShadow = (isDark = false) => {
  const shadow = isDark ? DROPDOWN_DRAK_SHADOW : DROPDOWN_LIGHT_SHADOW;
  return Platform.OS === "web" ? shadow : undefined;
};

export async function delay(ms: number) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export const getDomainName = (link?: string) => {
  if (!link) return null;
  const domainRegexp = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/gim;
  const results = domainRegexp.exec(link);
  if (!results) return null;
  return results[results?.length - 1];
};

export const getDomainNameV2 = (link?: string) => {
  if (!link) return null;
  const url = new URL(link);
  const hostname = url.hostname.replace("www.", "").split(".");
  const len = hostname.length;

  return [hostname[len - 2], hostname[len - 1]].join(".");
};

export const formatPgsqlString = (str: string) => {
  return `E'${str.replace(/\\/g, "\\\\").replace(/\'/g, "\\'")}'`;
};

export const formatPgsqlStringOrNull = (str: string) => {
  if (!str) {
    return null;
  }
  return `E'${str.replace(/\'/g, "\\'")}'`;
};

export const formatLink = (link: string) => {
  if (link.search(/^http[s]?:\/\//) !== -1) return link;
  return "https://" + link;
};

export function isAndroid(): boolean {
  return (
    typeof navigator !== "undefined" && /android/i.test(navigator.userAgent)
  );
}

export function isSmallIOS(): boolean {
  return (
    typeof navigator !== "undefined" && /iPhone|iPod/.test(navigator.userAgent)
  );
}

export function isLargeIOS(): boolean {
  return typeof navigator !== "undefined" && /iPad/.test(navigator.userAgent);
}

export function isIOS(): boolean {
  return isSmallIOS() || isLargeIOS();
}
export function isSafari(): boolean {
  return (
    typeof navigator !== "undefined" &&
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent)
  );
}

export function isMobileWeb(): boolean {
  return Platform.OS === "web" && (isAndroid() || isIOS());
}
export function isDesktopWeb(): boolean {
  return Platform.OS === "web" && !isAndroid() && !isIOS();
}

export function isClassComponent(component: any) {
  return (
    typeof component === "function" && !!component.prototype.isReactComponent
  );
}

export function isFunctionComponent(component: any) {
  return (
    typeof component === "function" &&
    String(component).includes("return React.createElement")
  );
}

export function isReactComponent(component: any) {
  if (!component) return false;
  return isClassComponent(component) || isFunctionComponent(component);
}

export const convertUTCDateToLocalDate = (dateStr: string) => {
  if (typeof dateStr !== "string") return new Date();
  // will be old UTC +0 time if include Z, so return time directly
  if (dateStr.includes("Z")) return new Date(dateStr);

  return new Date(dateStr + "Z");
};

export const obfuscatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  const obfuscated =
    phoneNumber.slice(0, 3) +
    "*".repeat(phoneNumber.length - 5) +
    phoneNumber.slice(-2);

  return obfuscated;
};

export const getFormatDistanceToNowStrict = (time?: string) => {
  if (!time) return "";
  return formatDistanceToNowStrict(new Date(time), { addSuffix: true });
};

// Format claim big numbers
export function formatClaimNumber(number: number) {
  if (!number) return 0;
  // for the edge case of 100k, our max supply, put “100k”, no decimals
  if (number >= 100000) {
    return `100k`;
  } else if (number > 9999) {
    return `${(number / 1000).toFixed(1)}k`;
  } else {
    return number;
  }
}

export const isProfileIncomplete = (profile?: Profile) => {
  // FYI: has_social_login is true if user has logged in with google, apple, spotify, twitter, instagram
  // the value is false if user has logged in with email or phone number
  return profile
    ? !profile.username ||
        (!profile.has_social_login && !profile.captcha_completed_at)
    : undefined;
};

export const getFormatDistanceStrictToWeek = (time?: string) => {
  if (!time) return "";
  const currentDate = new Date();
  const givenDate = new Date(time);
  const diffTime = currentDate.getTime() - givenDate.getTime();
  const diffMinutes = diffTime / (1000 * 60);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  const diffHours = diffTime / (1000 * 60 * 60);

  if (diffMinutes < 1) {
    return `now`;
  }

  if (diffMinutes >= 1 && diffMinutes < 60) {
    return `${Math.round(diffMinutes)}m`;
  }

  if (diffDays < 1) {
    return `${Math.round(diffHours)}h`;
  }

  return `${Math.ceil(diffDays / 7)}w`;
};

export const contentFitToresizeMode = (resizeMode: ImageResizeMode) => {
  switch (resizeMode) {
    case "cover":
      return ResizeMode.COVER;
    case "contain":
      return ResizeMode.CONTAIN;
    default:
      return ResizeMode.STRETCH;
  }
};

export const cleanUserTextInput = (text: string) => {
  return (
    text
      // normalize line breaks
      .replace(/\r\n|\r|\n/g, "\n")
      // remove extra line breaks (more than 1)
      .replace(/(\n){3,}/g, "\n")
      // remove leading and trailing line breaks and whitespace
      .trim()
  );
};

export const limitLineBreaks = (
  text: string,
  maxLineBreaks: number = 5,
  separator: string = " "
) => {
  return text
    .split("\n")
    .slice(0, maxLineBreaks)
    .concat(text.split("\n").slice(maxLineBreaks).join(separator).trim())
    .join("\n")
    .trim();
};

export const getWebImageSize = (file: File) => {
  const img = new Image();
  img.src = window.URL.createObjectURL(file);
  const promise = new Promise<
    { width: number; height: number } | null | undefined
  >((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
  return promise;
};

export const formatAPIErrorMessage = (error: AxiosError | Error) => {
  let messages = [];
  if (axios.isAxiosError(error)) {
    const res = error.response?.data;
    if (res.errors) {
      messages = res.errors.map((e: any) => e.message);
    } else if (res.error) {
      messages.push(res.error.message);
    }
  } else if (error.message) {
    messages.push(error.message);
  }

  return messages.join(".\n");
};

function getRandomDateWithinThreeMonths(): string {
  const now = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const randomTimestamp = Math.floor(
    threeMonthsAgo.getTime() +
      Math.random() * (now.getTime() - threeMonthsAgo.getTime())
  );

  return new Date(randomTimestamp).toISOString();
}

export const generateFakeData = (
  length: number,
  suffix: string = ""
): {
  id: string;
  username: string;
  date: string;
  // Add more keys here if needed
}[] => {
  return Array.from({ length }, (_, i) => ({
    id: i + 1 + suffix,
    username: `user${i + 1}_${suffix}`,
    date: getRandomDateWithinThreeMonths(),
    text: generateRandomLoremIpsum(),
    // Add more keys here if needed
  }));
};

export function formatDateRelativeWithIntl(isoDateString: string): string {
  const date = new Date(isoDateString);
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInMinutes < 1) {
    return "now";
  } else if (diffInDays < 1) {
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeFormatter.format(date);
  } else if (diffInDays >= 1 && diffInDays < 7) {
    return `${diffInDays}d`;
  } else {
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30.44);
    const diffInYears = Math.floor(diffInDays / 365.25);

    if (diffInWeeks < 4) {
      return `${diffInWeeks}w`;
    } else if (diffInMonths < 1) {
      return `${diffInWeeks}w`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths}m`;
    } else {
      return `${diffInYears}y`;
    }
  }
}

export function getRandomNumber(min = 50, max = 26000) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function dataExists(data: any) {
  const notNull = data !== null;
  if (typeof data === "object") {
    return (
      (notNull && Object.keys(data).length !== 0) ||
      toString.call(data) != "[object Object]"
    );
  }

  return data !== "" && typeof data !== "undefined" && notNull;
}

export let prevRouteRef = {
  current: null,
};

export function generateRandomLoremIpsum() {
  const words = [
    "lorem",
    "ipsum",
    "dolor",
    "sit",
    "amet",
    "consectetur",
    "adipiscing",
    "elit",
    "curabitur",
    "vel",
    "hendrerit",
    "libero",
    "eleifend",
    "blandit",
    "nunc",
    "ornare",
    "odio",
    "ut",
    "orci",
    "gravida",
    "imperdiet",
    "nullam",
    "purus",
    "lacinia",
    "a",
    "pretium",
    "quis",
    "congue",
    "praesent",
    "sagittis",
    "laoreet",
    "auctor",
    "mauris",
    "non",
    "velit",
    "eros",
    "dictum",
    "proin",
    "accumsan",
    "sapien",
    "nec",
    "massa",
    "volutpat",
    "venenatis",
    "sed",
    "eu",
    "molestie",
  ];

  const minWordsCount = 3;
  const maxWordsCount = 15;
  const wordsCount =
    Math.floor(Math.random() * (maxWordsCount - minWordsCount + 1)) +
    minWordsCount;

  const result = [];

  for (let i = 0; i < wordsCount; i++) {
    const randomIndex = Math.floor(Math.random() * words.length);
    result.push(words[randomIndex]);
  }

  return result.join(" ");
}

export function shortenLongWords(str: string, maxLength: number = 35): string {
  let words: string[] = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > maxLength) {
      let partLengthStart: number = Math.floor((maxLength - 3) * 0.7); // 70% of the length for the start
      let partLengthEnd: number = maxLength - 3 - partLengthStart; // Remaining for the end
      words[i] =
        words[i].substring(0, partLengthStart) +
        "..." +
        words[i].substring(words[i].length - partLengthEnd);
    }
  }
  return words.join(" ");
}

export const isFile = (object: any): boolean => {
  return object.constructor?.name == "File";
};

export const dup = (object: any): any => {
  return JSON.parse(JSON.stringify(object));
};

export const formateDate = (date: string = ""): string => {
  return date.split("T")[0];
};

export const formateDateTime = (
  date: string = "",
  withoutSeconds: boolean = true
): string => {
  let [date2, time]: any = date.split("T");
  if (withoutSeconds) {
    time = time.split(":");
    time = [time[0], time[1]].join(":");
  }
  return [date2, time.split(".")[0]].join(" ");
};

export const formateLocalDateTime = (
  date: Date = new Date(),
  withoutSeconds: boolean = true
): string => {
  let [date2, time]: any = date.toLocaleString().split(", ");
  if (withoutSeconds) {
    const pm = time.split(" ")[1];
    time = time.split(":");
    time = [time[0], time[1]].join(":");
    time = [time, pm].join(" ");
  }
  return [date2, time.split(".")[0]].join(" ");
};

export const formatUnixDateTime = (ts: number = 0): string => {
  return formateDateTime(new Date(ts * 1000).toISOString());
};

export const abbreviateNumber = (
  num: number,
  fixed = 2,
  ignoreNegativePower = false
) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num < 0.00001 && ignoreNegativePower) {
    // 4e-9 is number but NOT < 0??
    return "< 0.00001";
  }
  if (num <= 0) {
    return num;
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
};
