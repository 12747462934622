import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDiscord,
  faXTwitter,
  faTelegram,
  faMedium,
  faBitcoin,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faHouse,
  faHeart,
  faMagnifyingGlass,
  faUser,
  faShoppingCart,
  faCartPlus,
  faTrashCan,
  faList,
  faCalendarDays,
  faEye,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faGear,
  faSunBright,
  faMoon,
  faMoonOverSun,
  faCrosshairsSimple,
  faBarsFilter,
  faCheck,
  faXmark,
  faArrowRightFromBracket,
  faHammer,
  faFilm,
  faFlag,
  faUpload,
  faTriangleExclamation,
  faSubtitles,
  faSquareInfo,
  faImage,
  faBook,
  faMasksTheater,
  faStar,
  faComment,
  faRobot,
  faBars,
  faInfo,
  faSparkles,
  faChartSimple,
  faSeedling,
  faClipboard,
  faMoneyBill,
  faWallet,
  faPowerOff,
  faSwap,
  faDroplet,
  faFaucet,
  faCircleInfo,
  faCircleExclamation,
  faCircleCheck,
  faArrowUpRightFromSquare,
  faNetworkWired,
  faGlobe,
  faShop,
  faChartMixed,
  faCopy,
  faUpRightFromSquare,
  faPlus,
  faMinus,
  faVault,
  faFire,
  faStarShooting,
  faScrewdriverWrench,
  faCartShoppingFast,
  faRadar,
  faEnvelope,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faHouse as fasHouse,
  faAdd as fasAdd,
  faList as fasList,
  faMagnifyingGlass as fasMagnifyingGlass,
  faMinus as fasMinus,
  faUser as fasUser,
  faStar as fasStar,
  faSparkles as fasSparkles,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faDiscord,
  faXTwitter,
  faTelegram,
  faMedium,
  faBitcoin,
  faSquareXTwitter,

  faHouse,
  faHeart,
  faMagnifyingGlass,
  faUser,
  faShoppingCart,
  faCartPlus,
  faTrashCan,
  faList,
  faCalendarDays,
  faEye,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faGear,
  faSunBright,
  faMoon,
  faMoonOverSun,
  faCrosshairsSimple,
  faBarsFilter,
  faCheck,
  faXmark,
  faArrowRightFromBracket,
  faHammer,
  faFilm,
  faFlag,
  faUpload,
  faTriangleExclamation,
  faSubtitles,
  faSquareInfo,
  faImage,
  faBook,
  faMasksTheater,
  faStar,
  faComment,
  faRobot,
  faBars,
  faInfo,
  faSparkles,
  faChartSimple,
  faSeedling,
  faClipboard,
  faMoneyBill,
  faWallet,
  faPowerOff,
  faSwap,
  faDroplet,
  faFaucet,
  faCircleInfo,
  faCircleExclamation,
  faCircleCheck,
  faArrowUpRightFromSquare,
  faNetworkWired,
  faGlobe,
  faShop,
  faChartMixed,
  faCopy,
  faUpRightFromSquare,
  faPlus,
  faMinus,
  faVault,
  faFire,
  faStarShooting,
  faScrewdriverWrench,
  faCartShoppingFast,
  faRadar,
  faEnvelope,

  fasHouse,
  fasList,
  fasMagnifyingGlass,
  fasAdd,
  fasMinus,
  fasUser,
  fasStar,
  fasSparkles
);
