import * as React from "react";

import Svg, { SvgProps, Path, G } from "react-native-svg";

const SvgShoppingCart = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 90 90" fill="none" {...props}>
    <G mask="url(#ShoppingCart_svg__a)">
      <Path
        d="M74.396 66.93h-47.12c-3.491 0-5.549-2.665-5.777-5.299-.178-2.057.741-4.326 2.792-5.506L16.745 22.34a2.002 2.002 0 0 1 1.953-2.437H88a2.002 2.002 0 0 1 1.889 2.659l-7.983 22.876a8.304 8.304 0 0 1-6.406 5.438l-48.522 8.48a.79.79 0 0 1-.019.003c-1.499.267-1.507 1.541-1.473 1.926.033.386.261 1.644 1.792 1.644h47.12a2 2 0 1 1-.002 4.001zM21.193 23.904l6.966 31.186 46.652-8.152a4.301 4.301 0 0 0 3.318-2.817l7.055-20.216H21.193zM27.846 83.111a6.562 6.562 0 0 1-6.555-6.555c0-3.615 2.94-6.556 6.555-6.556s6.556 2.94 6.556 6.556a6.564 6.564 0 0 1-6.556 6.555zm0-9.11a2.558 2.558 0 0 0-2.555 2.556 2.558 2.558 0 0 0 2.555 2.555 2.559 2.559 0 0 0 2.556-2.555 2.56 2.56 0 0 0-2.556-2.556zM68.845 83.111a6.563 6.563 0 0 1-6.556-6.555c0-3.615 2.94-6.556 6.556-6.556s6.556 2.94 6.556 6.556a6.563 6.563 0 0 1-6.556 6.555zm0-9.11a2.559 2.559 0 0 0-2.556 2.556c0 1.408 1.146 2.555 2.556 2.555s2.556-1.146 2.556-2.555a2.56 2.56 0 0 0-2.556-2.556z"
        fill={props.color}
      />
      <Path
        d="M18.695 23.904a2 2 0 0 1-1.95-1.564l-1.407-6.301a6.548 6.548 0 0 0-6.428-5.151H2a2 2 0 0 1 0-4h6.909c4.995 0 9.244 3.404 10.333 8.279l1.407 6.301a2 2 0 0 1-1.954 2.436z"
        fill={props.color}
      />
    </G>
  </Svg>
);

export default SvgShoppingCart;
