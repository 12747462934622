import { Button } from "@showtime-xyz/universal.button";
import { useIsDarkMode } from "@showtime-xyz/universal.hooks";
import { Plus } from "@showtime-xyz/universal.icon";
import { PressableHover } from "@showtime-xyz/universal.pressable-hover";
import { useRouter } from "@showtime-xyz/universal.router";
import { View } from "@showtime-xyz/universal.view";
import { HeaderDropdown } from "app/components/header-dropdown";
import { TrendingTabBarIcon } from "app/navigation/tab-bar-icons";
import { useNavigateToLogin } from "app/navigation/use-navigate-to";

import { NotificationsInHeader } from "./header";

type HeaderEndProps = {
  withBackground?: boolean;
};
export const HeaderEnd = ({ withBackground }: HeaderEndProps) => {
  const router = useRouter();
  const isDark = useIsDarkMode();
  const navigateToLogin = useNavigateToLogin();

  return <View></View>;
};
