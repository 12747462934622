import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const ShowtimeWordmark = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 140 24" fill="none" {...props}>
    <Path
      d="M20.591.171A.171.171 0 0 0 20.42 0h-3.776a.172.172 0 0 0-.171.171v22.972c0 .095.077.171.172.171h3.775a.172.172 0 0 0 .171-.171V10.114c0-.094.077-.171.172-.171h3.26a3.43 3.43 0 0 1 3.432 3.428v9.772c0 .095.077.171.172.171h3.775a.172.172 0 0 0 .171-.171V13.37c0-4.165-3.38-7.542-7.55-7.542h-3.26a.171.171 0 0 1-.172-.172V.171ZM90.602 6.343c0-.095.076-.172.171-.172h3.775c.095 0 .172.077.172.172v16.8a.172.172 0 0 1-.172.171h-3.775a.172.172 0 0 1-.171-.171v-16.8Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.792 14.743c0 4.923-3.995 8.914-8.923 8.914s-8.923-3.99-8.923-8.914c0-4.923 3.995-8.914 8.923-8.914s8.923 3.99 8.923 8.914Zm-4.119 0c0 2.65-2.15 4.8-4.804 4.8a4.802 4.802 0 0 1-4.805-4.8c0-2.651 2.151-4.8 4.805-4.8a4.802 4.802 0 0 1 4.804 4.8Z"
      fill={props.color}
    />
    <Path
      d="M80.134 6.171A.171.171 0 0 0 80.306 6V.171c0-.094.076-.171.171-.171h3.775c.095 0 .172.077.172.171V6c0 .095.077.171.171.171h3.776c.094 0 .171.077.171.172v3.771a.171.171 0 0 1-.171.172h-3.776a.172.172 0 0 0-.171.171v6.686c0 1.136.922 2.057 2.059 2.057h1.888c.094 0 .171.077.171.171v3.772a.172.172 0 0 1-.171.171h-1.888a6.174 6.174 0 0 1-6.177-6.171v-6.686a.172.172 0 0 0-.172-.171h-2.402a.171.171 0 0 1-.172-.172V6.343c0-.095.077-.172.172-.172h2.402ZM5.32 6.171A5.317 5.317 0 0 0 0 11.486 5.317 5.317 0 0 0 5.32 16.8h4.46a1.2 1.2 0 1 1 0 2.4H.173a.172.172 0 0 0-.172.171v3.772c0 .095.077.171.172.171H9.78c2.938 0 5.32-2.379 5.32-5.314a5.317 5.317 0 0 0-5.32-5.314H5.319a1.2 1.2 0 1 1 0-2.4h8.58a.171.171 0 0 0 .172-.172V6.343a.171.171 0 0 0-.172-.172h-8.58ZM51.681 6.171a.172.172 0 0 0-.169.203l3.11 16.8c.014.081.085.14.168.14h6.786a.172.172 0 0 0 .169-.14l2.103-11.368c.035-.187.303-.187.338 0l2.104 11.368c.015.081.086.14.168.14h6.786a.172.172 0 0 0 .17-.14l3.108-16.8a.171.171 0 0 0-.169-.203h-3.838a.171.171 0 0 0-.17.14L69.98 19.094a.131.131 0 0 1-.258 0L67.357 6.312a.172.172 0 0 0-.169-.14h-6.342a.172.172 0 0 0-.169.14l-2.365 12.78a.131.131 0 0 1-.258 0l-2.365-12.78a.172.172 0 0 0-.169-.14h-3.839ZM110.334 23.314a.172.172 0 0 0 .172-.171V10.457c0-.095.077-.171.172-.171h2.574a2.744 2.744 0 0 1 2.745 2.743v10.114c0 .095.077.171.172.171h3.775a.172.172 0 0 0 .171-.171V13.029a6.86 6.86 0 0 0-6.863-6.858H96.95a.171.171 0 0 0-.171.172v16.8c0 .095.076.171.171.171h3.775a.172.172 0 0 0 .172-.171V10.457c0-.095.077-.171.171-.171h2.574a2.744 2.744 0 0 1 2.746 2.74v10.117c0 .095.077.171.171.171h3.775ZM90.601 1.2c0-.095.077-.171.172-.171h3.775c.095 0 .171.076.171.171v3.429a.171.171 0 0 1-.171.171h-3.775a.171.171 0 0 1-.172-.171V1.2Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.411 19.543a4.807 4.807 0 0 1-4.535-3.21.165.165 0 0 1 .159-.219h13.127a.172.172 0 0 0 .172-.171v-1.2c0-4.923-3.995-8.914-8.923-8.914s-8.923 3.99-8.923 8.914c0 4.923 3.995 8.914 8.923 8.914 2.434 0 4.796-.976 6.556-2.52a9.57 9.57 0 0 0 1.672-1.913c.06-.09.02-.21-.081-.25l-3.661-1.421a.174.174 0 0 0-.192.046 5.695 5.695 0 0 1-.457.447c-1.068.938-2.481 1.497-3.837 1.497Zm0-9.6a4.808 4.808 0 0 1 4.535 3.21.166.166 0 0 1-.16.218h-8.751a.165.165 0 0 1-.159-.218 4.807 4.807 0 0 1 4.535-3.21Z"
      fill={props.color}
    />
  </Svg>
);

export default ShowtimeWordmark;
