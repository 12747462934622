import { DESKTOP_LEFT_MENU_WIDTH } from "app/constants/layout";

const breakpoints: any = {
  base: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

let contentBreakpoints: any = {};
Object.keys(breakpoints).forEach((bp: string) => {
  contentBreakpoints[bp] = breakpoints[bp] - DESKTOP_LEFT_MENU_WIDTH;
});

export type IBreakpoints = keyof typeof breakpoints;

const sortedBreakpointKeys = Object.keys(breakpoints).sort((a, b) =>
  //@ts-ignore
  breakpoints[a] > breakpoints[b] ? -1 : 1
) as Array<IBreakpoints>;

export { contentBreakpoints, breakpoints, sortedBreakpointKeys };
