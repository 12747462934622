import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

import {
  MAX_CONTENT_WIDTH,
  DESKTOP_LEFT_MENU_WIDTH,
  DESKTOP_CONTENT_WIDTH,
} from "app/constants/layout";

import { breakpoints } from "design-system/theme";

export enum ContentLayoutOffset {
  HEADER = -240,
}

export const useIsMd = () => {
  const { width } = useWindowDimensions();

  return useMemo(() => {
    return width >= breakpoints["md"];
  }, [width]);
};

export const useIsSm = () => {
  const { width } = useWindowDimensions();

  return useMemo(() => {
    return width >= breakpoints["sm"];
  }, [width]);
};

export function useContentWidth(offset = 0) {
  const { width } = useWindowDimensions();
  const isMdWidth = useIsMd();

  const contentWidth = useMemo(() => {
    return isMdWidth
      ? Math.min(DESKTOP_CONTENT_WIDTH, width - DESKTOP_LEFT_MENU_WIDTH) -
          offset
      : width - offset;
  }, [isMdWidth, offset, width]);
  return contentWidth;
}

export function usePageWidth(offset = 0) {
  const { width } = useWindowDimensions();
  const isMdWidth = useIsMd();
  const leftMenuWidth = isMdWidth ? DESKTOP_LEFT_MENU_WIDTH : 0;

  const contentWidth = useMemo(() => {
    return width - leftMenuWidth + (isMdWidth ? 20 : 0);
    width < MAX_CONTENT_WIDTH + leftMenuWidth - offset
      ? width - leftMenuWidth
      : MAX_CONTENT_WIDTH - offset;
  }, [offset, width, leftMenuWidth, isMdWidth]);
  return contentWidth;
}
