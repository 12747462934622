import { API_URLS } from "@raydium-io/raydium-sdk-v2";
import { PublicKey, EpochInfo } from "@solana/web3.js";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { retry } from "app/lib/utilities";

import { useWalletStore } from "./wallet-store";

export enum PriorityLevel {
  Fast,
  Turbo,
  Ultra,
}

export enum PriorityMode {
  MaxCap,
  Exact,
}

const appInitState = {
  urlConfigs: API_URLS,

  blockSlotCountForSecond: 0,
  slippage: 0.005,
  commitment: "confirmed",

  priorityLevel: PriorityLevel.Turbo,
  priorityMode: PriorityMode.MaxCap,
  feeConfig: {},
  transactionFee: "0.0003",
};

let epochInfoCache = {
  time: 0,
  loading: false,
};

const useAppStore = createWithEqualityFn(
  (set: Function, get: Function) =>
    ({
      ...appInitState,
      isAdmin: false,
      setIsAdmin: (publicKey: PublicKey) => {
        const isAdmin = [
          "9qGrggugGSc5U5yqoKRuSuCCepQrWCZwm1BCru4QBvdC",
          "FUvXAyvQgC6GPyvMMedWhYFTycpTRU7Msuqcs4Ao1hC8",
        ].includes(publicKey?.toBase58?.());

        set({ isAdmin });
      },
      feeExempted: () => {
        return get().isAdmin;
      },

      fetchPriorityFeeAct: async () => {
        const { urlConfigs } = get();
        const { data } = await axios.get<{
          data: {
            default: {
              h: number;
              m: number;
              vh: number;
            };
          };
        }>(`${urlConfigs.BASE_HOST}${urlConfigs.PRIORITY_FEE}`);

        set({
          feeConfig: {
            [PriorityLevel.Fast]: data.data.default.m / 10 ** 9,
            [PriorityLevel.Turbo]: data.data.default.h / 10 ** 9,
            [PriorityLevel.Ultra]: data.data.default.vh / 10 ** 9,
          },
        });
      },

      getPriorityFee: () => {
        const { priorityMode, priorityLevel, transactionFee, feeConfig } =
          get();
        if (priorityMode === PriorityMode.Exact) {
          return transactionFee ? String(transactionFee) : transactionFee;
        }
        if (
          feeConfig[priorityLevel] === undefined ||
          transactionFee === undefined
        ) {
          return String(feeConfig[PriorityLevel.Turbo] ?? 0);
        }
        return String(
          Math.min(Number(transactionFee), feeConfig[priorityLevel]!)
        );
      },

      getEpochInfo: async () => {
        const [connection, epochInfo] = [
          useWalletStore.getState().connection,
          get().epochInfo,
        ];
        if (!connection) {
          return undefined;
        }
        if (epochInfo && Date.now() - epochInfoCache.time <= 30 * 1000) {
          return epochInfo;
        }
        if (epochInfoCache.loading) {
          return epochInfo;
        }
        epochInfoCache.loading = true;
        const newEpochInfo = await retry<Promise<EpochInfo>>(() =>
          connection.getEpochInfo()
        );
        epochInfoCache = { time: Date.now(), loading: false };
        set({ epochInfo: newEpochInfo }, false, {
          type: "useAppStore.getEpochInfo",
        });
        return newEpochInfo;
      },
    } as any),
  shallow
);

export { useAppStore };
