import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgQrCode = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375ZM15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375ZM22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375ZM22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375ZM14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375ZM21 1.5h-6.75a1.5 1.5 0 0 0-1.5 1.5v6.75a1.5 1.5 0 0 0 1.5 1.5H21a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 21 1.5Zm-1.5 6.375a.375.375 0 0 1-.375.375h-3a.375.375 0 0 1-.375-.375v-3a.375.375 0 0 1 .375-.375h3a.375.375 0 0 1 .375.375v3ZM9.75 1.5H3A1.5 1.5 0 0 0 1.5 3v6.75a1.5 1.5 0 0 0 1.5 1.5h6.75a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5Zm-1.5 6.375a.375.375 0 0 1-.375.375h-3a.375.375 0 0 1-.375-.375v-3a.375.375 0 0 1 .375-.375h3a.375.375 0 0 1 .375.375v3ZM9.75 12.75H3a1.5 1.5 0 0 0-1.5 1.5V21A1.5 1.5 0 0 0 3 22.5h6.75a1.5 1.5 0 0 0 1.5-1.5v-6.75a1.5 1.5 0 0 0-1.5-1.5Zm-1.5 6.375a.375.375 0 0 1-.375.375h-3a.375.375 0 0 1-.375-.375v-3a.375.375 0 0 1 .375-.375h3a.375.375 0 0 1 .375.375v3Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgQrCode;
