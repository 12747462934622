import { Roboto, Noto_Naskh_Arabic } from "next/font/google";

const arRegular = Noto_Naskh_Arabic({
  subsets: ["arabic"],
  variable: "--font-primary",
});
const arBold = Noto_Naskh_Arabic({
  weight: "700",
  subsets: ["arabic"],
  variable: "--font-primary-bold",
});
const ar = [arRegular, arBold];

const enRegular = Roboto({
  weight: "100",
  subsets: ["latin"],
  variable: "--font-secondary",
});
const enBold = Roboto({
  weight: "700",
  subsets: ["latin"],
  variable: "--font-secondary-bold",
});
const en = [enRegular, enBold];

const SecondaryFont = {
  ar,
  en,
};

export { SecondaryFont };
