import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const ShowtimeBrand = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 255 45" fill="none" {...props}>
    <Path
      d="M37.685.321A.318.318 0 0 0 37.37 0h-6.91a.318.318 0 0 0-.313.321v43.072c0 .177.14.321.314.321h6.909a.318.318 0 0 0 .314-.321V18.964c0-.177.14-.321.314-.321h5.966c3.47 0 6.281 2.878 6.281 6.428v18.322c0 .177.14.321.314.321h6.91a.318.318 0 0 0 .313-.321V25.07c0-7.81-6.186-14.142-13.818-14.142H38a.318.318 0 0 1-.314-.322V.321ZM165.813 11.893c0-.178.141-.322.314-.322h6.909c.174 0 .314.144.314.322v31.5c0 .177-.14.321-.314.321h-6.909a.318.318 0 0 1-.314-.321v-31.5Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.956 27.643c0 9.23-7.312 16.714-16.33 16.714-9.02 0-16.33-7.483-16.33-16.714 0-9.231 7.31-16.714 16.33-16.714 9.018 0 16.33 7.483 16.33 16.714Zm-7.537 0c0 4.97-3.937 9-8.793 9-4.857 0-8.794-4.03-8.794-9s3.937-9 8.794-9c4.856 0 8.793 4.03 8.793 9Z"
      fill={props.color}
    />
    <Path
      d="M146.656 11.571a.317.317 0 0 0 .314-.321V.321c0-.177.141-.321.314-.321h6.909c.174 0 .314.144.314.321V11.25c0 .178.141.321.314.321h6.909c.174 0 .314.144.314.322v7.071c0 .178-.14.322-.314.322h-6.909a.318.318 0 0 0-.314.321v12.536c0 2.13 1.688 3.857 3.769 3.857h3.454c.174 0 .314.144.314.321v7.072c0 .177-.14.321-.314.321h-3.454c-6.244 0-11.306-5.18-11.306-11.571V19.607a.317.317 0 0 0-.314-.321h-4.396a.317.317 0 0 1-.314-.322v-7.071c0-.178.14-.322.314-.322h4.396ZM9.735 11.571C4.36 11.571 0 16.033 0 21.536S4.36 31.5 9.735 31.5H17.9c1.214 0 2.199 1.007 2.199 2.25S19.114 36 17.9 36H.314a.318.318 0 0 0-.314.321v7.072c0 .177.14.321.314.321H17.9c5.377 0 9.736-4.46 9.736-9.964 0-5.503-4.36-9.964-9.736-9.964H9.735c-1.214 0-2.198-1.008-2.198-2.25 0-1.243.984-2.25 2.198-2.25h15.702a.318.318 0 0 0 .314-.322v-7.071a.318.318 0 0 0-.314-.322H9.735ZM94.583 11.571a.32.32 0 0 0-.308.38l5.69 31.5a.316.316 0 0 0 .308.263h12.42c.151 0 .281-.11.308-.263l3.851-21.314c.063-.35.554-.35.617 0l3.85 21.314a.317.317 0 0 0 .309.263h12.42c.151 0 .281-.11.308-.263l5.69-31.5a.319.319 0 0 0-.308-.38h-7.026a.315.315 0 0 0-.309.263L128.074 35.8a.241.241 0 0 1-.236.201.241.241 0 0 1-.236-.201l-4.329-23.965a.317.317 0 0 0-.309-.263h-11.607a.315.315 0 0 0-.309.263L106.719 35.8a.241.241 0 0 1-.236.201.241.241 0 0 1-.236-.201l-4.329-23.965a.315.315 0 0 0-.309-.263h-7.026ZM201.927 43.714a.317.317 0 0 0 .314-.321V19.607c0-.177.141-.321.314-.321h4.711c2.775 0 5.025 2.302 5.025 5.143v18.964c0 .177.14.321.314.321h6.909a.318.318 0 0 0 .314-.321V24.429c0-7.101-5.624-12.858-12.562-12.858h-29.834a.318.318 0 0 0-.314.322v31.5c0 .177.141.321.314.321h6.909a.317.317 0 0 0 .314-.321V19.607c0-.177.141-.321.314-.321h4.711c2.774 0 5.023 2.3 5.024 5.139v18.968c0 .177.141.321.314.321h6.909ZM165.813 2.25c0-.178.14-.321.314-.321h6.909c.173 0 .314.143.314.321v6.429a.318.318 0 0 1-.314.321h-6.909a.317.317 0 0 1-.314-.321V2.25Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M238.67 36.643c-3.835 0-7.097-2.513-8.299-6.019a.309.309 0 0 1 .291-.41h24.024a.318.318 0 0 0 .314-.321v-2.25c0-9.231-7.311-16.714-16.33-16.714-9.019 0-16.33 7.483-16.33 16.714 0 9.23 7.311 16.714 16.33 16.714 4.455 0 8.778-1.83 11.998-4.725a17.801 17.801 0 0 0 3.062-3.586.32.32 0 0 0-.149-.468l-6.7-2.667a.316.316 0 0 0-.353.087c-.257.29-.536.57-.835.839-1.954 1.757-4.54 2.806-7.023 2.806Zm0-18c3.835 0 7.097 2.513 8.299 6.018a.309.309 0 0 1-.291.41h-16.016a.309.309 0 0 1-.291-.41c1.202-3.505 4.464-6.018 8.299-6.018Z"
      fill={props.color}
    />
  </Svg>
);

export default ShowtimeBrand;
