import * as React from "react";

import Svg, {
  SvgProps,
  G,
  Path,
  Defs,
  RadialGradient,
  Stop,
  ClipPath,
} from "react-native-svg";

const SvgInstagramColorful = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#InstagramColorful_svg__a)">
      <Path
        d="M11.959 2c-4.147 0-5.36.004-5.596.024-.851.07-1.38.205-1.958.492-.444.22-.795.477-1.141.835-.63.655-1.012 1.46-1.15 2.416-.068.465-.087.56-.091 2.932-.002.791 0 1.832 0 3.228 0 4.144.004 5.355.024 5.59.069.829.199 1.35.474 1.92a4.185 4.185 0 0 0 2.712 2.215c.41.106.862.164 1.442.191.246.011 2.754.019 5.263.019 2.508 0 5.017-.003 5.257-.016.672-.031 1.063-.084 1.495-.195a4.16 4.16 0 0 0 2.712-2.22c.27-.556.406-1.097.468-1.882.013-.171.02-2.9.02-5.625s-.007-5.448-.02-5.62c-.063-.797-.2-1.333-.478-1.9a3.939 3.939 0 0 0-.85-1.165c-.658-.628-1.461-1.01-2.42-1.148-.463-.067-.556-.087-2.93-.091h-3.233Z"
        fill="url(#InstagramColorful_svg__b)"
      />
      <Path
        d="M11.959 2c-4.147 0-5.36.004-5.596.024-.851.07-1.38.205-1.958.492-.444.22-.795.477-1.141.835-.63.655-1.012 1.46-1.15 2.416-.068.465-.087.56-.091 2.932-.002.791 0 1.832 0 3.228 0 4.144.004 5.355.024 5.59.069.829.199 1.35.474 1.92a4.185 4.185 0 0 0 2.712 2.215c.41.106.862.164 1.442.191.246.011 2.754.019 5.263.019 2.508 0 5.017-.003 5.257-.016.672-.031 1.063-.084 1.495-.195a4.16 4.16 0 0 0 2.712-2.22c.27-.556.406-1.097.468-1.882.013-.171.02-2.9.02-5.625s-.007-5.448-.02-5.62c-.063-.797-.2-1.333-.478-1.9a3.939 3.939 0 0 0-.85-1.165c-.658-.628-1.461-1.01-2.42-1.148-.463-.067-.556-.087-2.93-.091h-3.233Z"
        fill="url(#InstagramColorful_svg__c)"
      />
      <Path
        d="M11.954 4.598c-1.992 0-2.242.008-3.024.044-.78.036-1.314.16-1.78.34a3.591 3.591 0 0 0-1.3.846 3.6 3.6 0 0 0-.846 1.3c-.182.466-.305.999-.34 1.78-.035.782-.044 1.032-.044 3.024s.008 2.24.044 3.023c.036.78.16 1.314.34 1.78.188.483.439.892.846 1.3.408.407.817.659 1.3.846.466.181.999.305 1.78.34.782.036 1.032.045 3.023.045 1.992 0 2.242-.009 3.024-.044.78-.036 1.314-.16 1.78-.341a3.59 3.59 0 0 0 1.3-.847 3.6 3.6 0 0 0 .846-1.299c.18-.466.304-1 .34-1.78.036-.782.045-1.031.045-3.023 0-1.992-.01-2.242-.045-3.024-.036-.781-.16-1.314-.34-1.78a3.6 3.6 0 0 0-.847-1.3 3.586 3.586 0 0 0-1.299-.845c-.467-.182-1-.305-1.781-.341-.782-.036-1.032-.044-3.024-.044h.002Zm-.658 1.321h.658c1.958 0 2.19.007 2.964.042.715.033 1.103.153 1.361.253.343.133.587.292.843.548.257.257.416.502.55.844.1.258.22.646.252 1.361.035.773.043 1.006.043 2.963s-.008 2.19-.043 2.963c-.033.715-.152 1.103-.253 1.361a2.27 2.27 0 0 1-.549.843 2.27 2.27 0 0 1-.843.548c-.258.101-.646.22-1.361.253-.773.035-1.006.043-2.964.043s-2.19-.008-2.963-.043c-.716-.033-1.104-.153-1.362-.253a2.273 2.273 0 0 1-.844-.549 2.272 2.272 0 0 1-.549-.843c-.1-.258-.22-.646-.252-1.36-.035-.774-.042-1.006-.042-2.965 0-1.958.007-2.19.042-2.963.033-.715.152-1.103.252-1.361.133-.343.293-.587.55-.844.256-.256.5-.415.843-.548.258-.101.646-.22 1.362-.253.676-.03.938-.04 2.305-.042v.002Zm4.573 1.218a.88.88 0 1 0 0 1.761.88.88 0 0 0 0-1.761Zm-3.915 1.028a3.767 3.767 0 1 0 0 7.533 3.767 3.767 0 0 0 0-7.533Zm0 1.322a2.445 2.445 0 1 1 0 4.89 2.445 2.445 0 0 1 0-4.89Z"
        fill="#fff"
      />
    </G>
    <Defs>
      <RadialGradient
        id="InstagramColorful_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -19.6845 18.313 0 7.3 23.392)"
      >
        <Stop stopColor="#FD5" />
        <Stop offset={0.1} stopColor="#FD5" />
        <Stop offset={0.5} stopColor="#FF543E" />
        <Stop offset={1} stopColor="#C837AB" />
      </RadialGradient>
      <RadialGradient
        id="InstagramColorful_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1.72753 8.62787 -35.57333 7.12272 -1.306 3.43)"
      >
        <Stop stopColor="#3771C8" />
        <Stop offset={0.128} stopColor="#3771C8" />
        <Stop offset={1} stopColor="#60F" stopOpacity={0} />
      </RadialGradient>
      <ClipPath id="InstagramColorful_svg__a">
        <Path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgInstagramColorful;
