import dynamic from "next/dynamic";
import { GestureHandlerRootView } from "react-native-gesture-handler";

import { BottomSheetModalProvider } from "@showtime-xyz/universal.bottom-sheet";
import { ColorSchemeProvider } from "@showtime-xyz/universal.color-scheme";
import { LightBoxProvider } from "@showtime-xyz/universal.light-box";
import { SafeAreaProvider } from "@showtime-xyz/universal.safe-area";
import { SnackbarProvider } from "@showtime-xyz/universal.snackbar";
import { View } from "@showtime-xyz/universal.view";

import { NavigationProvider } from "app/navigation";
import { SWRProvider } from "app/providers/swr-provider";
import { useConfigStore } from "app/stores/config-store";
import { Web3Provider } from "app/web3/provider";

import { MuteProvider } from "./mute-provider";

const AlertProvider = dynamic(() => import("@showtime-xyz/universal.alert"), {
  ssr: false,
});

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  const [fontMain] = useConfigStore((state) => [state.fontMain]);

  return (
    <View style={fontMain}>
      <GestureHandlerRootView>
        <ColorSchemeProvider>
          <SafeAreaProvider>
            <LightBoxProvider>
              <AlertProvider>
                <Web3Provider>
                  <SnackbarProvider>
                    <SWRProvider>
                      <BottomSheetModalProvider>
                        <NavigationProvider>
                          <MuteProvider>{children}</MuteProvider>
                        </NavigationProvider>
                      </BottomSheetModalProvider>
                    </SWRProvider>
                  </SnackbarProvider>
                </Web3Provider>
              </AlertProvider>
            </LightBoxProvider>
          </SafeAreaProvider>
        </ColorSchemeProvider>
      </GestureHandlerRootView>
    </View>
  );
};
