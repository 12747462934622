import { PressableScale } from "@showtime-xyz/universal.pressable-scale";
import { View } from "@showtime-xyz/universal.view";

import { BrandIcon } from "app/components/brand-icon";
import { Heading } from "app/components/commons/head";
import { FontAwesome } from "app/components/fontawesome";
import { HEADER_HEIGHT } from "app/lib/constants";
import { Link } from "app/navigation/link";
import { FONT_AWESOME_ICON_SIZE } from "app/navigation/sidebar";
import { useNavigationStore } from "app/stores/navigation-store";

const Header = ({ title }: any) => {
  const [toggleMobileDrawer] = useNavigationStore((state) => [
    state.toggleMobileDrawer,
  ]);

  return (
    <View
      tw="flex-row items-center justify-between bg-white py-2 shadow-md md:hidden"
      style={{ height: HEADER_HEIGHT }}
    >
      <PressableScale onPress={toggleMobileDrawer} tw="px-4 md:hidden">
        <FontAwesome
          icon="fa-regular fa-bars"
          fontSize={FONT_AWESOME_ICON_SIZE}
          fixedWidth
        />
      </PressableScale>
      <Heading label={title} />
      <Link href="/" tw="px-4">
        <BrandIcon />
      </Link>
    </View>
  );
};

export { Header };
