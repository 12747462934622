import * as React from "react";

import Svg, { Path, G, LinearGradient, Stop, SvgProps } from "react-native-svg";

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      id="Artwork"
      x={0}
      y={0}
      width={125.29}
      height={27.88}
      viewBox="0 0 125.29 27.88"
      {...props}
    >
      <Path
        fill={props.color}
        d="M38.36 20.52h-4.73l-1.14 3.36h-2l4.48-12.42h2.08l4.48 12.42h-2.04l-1.13-3.36zm-4.25-1.54h3.75l-1.85-5.45h-.05l-1.85 5.45zM51.22 19.35c0 2.81-1.51 4.62-3.78 4.62-1.29 0-2.31-.58-2.85-1.58h-.04v4.48h-1.86V14.83h1.8v1.51h.03c.52-.97 1.62-1.6 2.88-1.6 2.3-.01 3.82 1.81 3.82 4.61zm-1.91 0c0-1.83-.95-3.04-2.39-3.04-1.42 0-2.38 1.23-2.38 3.04 0 1.82.96 3.05 2.38 3.05 1.44 0 2.39-1.2 2.39-3.05zM61.18 19.35c0 2.81-1.51 4.62-3.78 4.62-1.29 0-2.31-.58-2.85-1.58h-.04v4.48h-1.86V14.83h1.8v1.51h.03c.52-.97 1.62-1.6 2.88-1.6 2.31-.01 3.82 1.81 3.82 4.61zm-1.91 0c0-1.83-.95-3.04-2.39-3.04-1.42 0-2.38 1.23-2.38 3.04 0 1.82.96 3.05 2.38 3.05 1.44 0 2.39-1.2 2.39-3.05zM62.68 11.46h1.86v12.42h-1.86V11.46zM74.22 21.22c-.25 1.64-1.85 2.77-3.9 2.77-2.63 0-4.27-1.76-4.27-4.6s1.64-4.68 4.19-4.68c2.5 0 4.08 1.72 4.08 4.47v.64h-6.39v.11c0 1.55.97 2.56 2.44 2.56 1.03 0 1.84-.49 2.09-1.27h1.76zm-6.28-2.7h4.53c-.04-1.39-.93-2.3-2.22-2.3s-2.22.93-2.31 2.3zM90.37 23.88v-9.14h-.06l-3.74 9.04h-1.43l-3.75-9.04h-.06v9.14h-1.76V11.46h2.23l4.02 9.8h.07l4.01-9.8h2.24v12.42h-1.77zM102.04 23.88h-1.78v-1.56h-.04c-.52 1.08-1.42 1.66-2.81 1.66-1.97 0-3.18-1.27-3.18-3.35v-5.81h1.86v5.45c0 1.38.65 2.11 1.94 2.11 1.34 0 2.15-.93 2.15-2.34v-5.22h1.86v9.06zM107.33 14.72c2.01 0 3.44 1.11 3.49 2.71h-1.75c-.08-.8-.76-1.29-1.79-1.29-1.01 0-1.68.46-1.68 1.17 0 .54.45.9 1.39 1.14l1.52.35c1.82.44 2.51 1.11 2.51 2.44 0 1.63-1.55 2.75-3.76 2.75-2.13 0-3.57-1.09-3.71-2.75h1.84c.13.87.83 1.33 1.95 1.33 1.11 0 1.81-.46 1.81-1.18 0-.56-.34-.86-1.29-1.1l-1.62-.4c-1.63-.4-2.46-1.23-2.46-2.49.01-1.58 1.45-2.68 3.55-2.68zM112.48 12.44c0-.59.48-1.07 1.08-1.07s1.08.47 1.08 1.07c0 .58-.48 1.06-1.08 1.06-.6 0-1.08-.47-1.08-1.06zm.15 2.39h1.86v9.05h-1.86v-9.05zM122.49 17.94c-.16-.96-.91-1.67-2.13-1.67-1.43 0-2.38 1.2-2.38 3.08 0 1.93.96 3.09 2.39 3.09 1.15 0 1.91-.58 2.12-1.63h1.79c-.21 1.9-1.73 3.18-3.92 3.18-2.58 0-4.27-1.76-4.27-4.64 0-2.81 1.69-4.64 4.25-4.64 2.32 0 3.77 1.46 3.92 3.23h-1.77z"
      />
      <G>
        <Path
          fill={props.color}
          d="M33.48 1.59c1.18 0 2 .82 2 2.02 0 1.18-.84 2-2.03 2h-1.4v2.04h-.94V1.59h2.37zm-1.43 3.19h1.18c.81 0 1.28-.42 1.28-1.17 0-.77-.46-1.19-1.28-1.19h-1.18v2.36zM36.7 3.08h.87v.7h.07c.13-.45.64-.78 1.23-.78.13 0 .31.01.4.04v.89c-.08-.03-.34-.05-.5-.05-.68 0-1.17.43-1.17 1.07v2.7h-.9V3.08zM44.01 6.42c-.21.82-.94 1.32-1.98 1.32-1.31 0-2.11-.9-2.11-2.36s.82-2.39 2.11-2.39c1.27 0 2.04.87 2.04 2.3v.31h-3.23v.05c.03.8.5 1.31 1.22 1.31.55 0 .92-.2 1.09-.55h.86v.01zm-3.17-1.48h2.31c-.02-.72-.46-1.18-1.12-1.18s-1.14.47-1.19 1.18zM45.29 5.65v-.88h2.75v.89l-2.75-.01zM49.22 6.36c0-.82.61-1.3 1.7-1.36l1.24-.07v-.4c0-.48-.32-.75-.94-.75-.5 0-.85.18-.95.51h-.87c.09-.79.83-1.29 1.87-1.29 1.15 0 1.79.57 1.79 1.54v3.12h-.87v-.65h-.07c-.27.46-.77.72-1.37.72-.88 0-1.53-.54-1.53-1.37zm2.94-.39v-.39l-1.12.07c-.63.04-.92.26-.92.66 0 .41.36.65.85.65.69.01 1.19-.43 1.19-.99zM54.24 5.36c0-1.44.74-2.36 1.9-2.36.62 0 1.15.3 1.4.8h.07V1.3h.9v6.35h-.86v-.72h-.07c-.27.5-.81.8-1.43.8-1.18 0-1.91-.92-1.91-2.37zm.93 0c0 .97.46 1.55 1.22 1.55s1.23-.59 1.23-1.55c0-.95-.47-1.55-1.23-1.55s-1.22.59-1.22 1.55zM59.75 5.36c0-1.44.74-2.36 1.9-2.36.62 0 1.15.3 1.4.8h.07V1.3h.9v6.35h-.86v-.72h-.07c-.27.5-.81.8-1.43.8-1.18 0-1.91-.92-1.91-2.37zm.93 0c0 .97.46 1.55 1.22 1.55s1.23-.59 1.23-1.55c0-.95-.47-1.55-1.23-1.55s-1.22.59-1.22 1.55zM67.74 5.36c0-1.47.82-2.37 2.16-2.37 1.33 0 2.15.9 2.15 2.37 0 1.48-.82 2.38-2.15 2.38-1.34 0-2.16-.9-2.16-2.38zm3.38 0c0-.99-.45-1.57-1.23-1.57s-1.23.58-1.23 1.57c0 1 .44 1.57 1.23 1.57.78.01 1.23-.57 1.23-1.57zM73.25 3.08h.87v.73h.07c.22-.51.68-.81 1.36-.81 1.02 0 1.58.61 1.58 1.7v2.96h-.9V4.92c0-.73-.32-1.1-.99-1.1s-1.09.45-1.09 1.16v2.67h-.9V3.08z"
        />
      </G>
      <G>
        <LinearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={12.58}
          y1={4.9345}
          x2={12.58}
          y2={29.4579}
          gradientTransform="matrix(1 0 0 -1 0 30)"
        >
          <Stop offset={0} stopColor="#fa233b" />
          <Stop offset={1} stopColor="#fb5c74" />
        </LinearGradient>
        <Path
          d="M25.16 7.87v-.9c0-.25 0-.51-.01-.76-.01-.55-.05-1.11-.15-1.65-.1-.55-.26-1.07-.52-1.57-.25-.49-.58-.95-.97-1.34s-.84-.72-1.34-.97c-.5-.26-1.02-.42-1.57-.52S19.5.03 18.95.01c-.25 0-.5-.01-.76-.01H6.97c-.25 0-.51 0-.76.01-.55.02-1.11.05-1.65.15-.56.1-1.07.26-1.57.52-.5.25-.95.58-1.34.97s-.72.84-.97 1.34C.42 3.49.26 4 .16 4.56c-.1.55-.13 1.1-.15 1.65 0 .25-.01.51-.01.76v11.22c0 .25 0 .51.01.76.01.55.05 1.11.15 1.65.1.55.26 1.07.52 1.57.25.49.58.95.97 1.34s.84.72 1.34.97c.5.26 1.01.43 1.57.52.55.1 1.1.13 1.65.15.25.01.51.01.76.01h11.22c.25 0 .51 0 .76-.01.55-.01 1.11-.05 1.65-.15.55-.1 1.07-.26 1.57-.52.49-.25.95-.58 1.34-.97s.72-.84.97-1.34c.26-.5.42-1.02.52-1.57s.13-1.1.15-1.65c.01-.25.01-.51.01-.76V7.87z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#SVGID_1_)"
        />
        <G id="Glyph_13_">
          <Path
            d="M17.79 3.84c-.06.01-.6.1-.67.11L9.64 5.47c-.2.04-.35.11-.47.21-.14.12-.22.29-.25.48 0 .05-.01.13-.01.26v9.36c0 .22-.02.43-.17.61s-.33.24-.55.28c-.16.03-.33.07-.49.1-.62.12-1.02.21-1.38.35-.35.14-.61.31-.82.52a1.997 1.997 0 00.1 2.81c.24.22.55.39.91.47.37.07.77.05 1.35-.07.31-.06.6-.16.87-.32s.51-.38.69-.64c.18-.26.3-.55.37-.86.07-.32.08-.61.08-.93V9.98c0-.44.12-.55.47-.63 0 0 6.22-1.25 6.51-1.31.41-.08.6.04.6.46v5.54c0 .22 0 .44-.15.62s-.33.24-.55.28c-.16.03-.33.07-.49.1-.62.12-1.02.21-1.38.35-.35.14-.61.31-.82.52-.41.43-.59 1.02-.54 1.56.05.47.27.91.64 1.25.24.22.55.39.91.46.37.07.77.05 1.35-.07.31-.06.6-.15.87-.32.27-.16.51-.38.69-.64s.3-.55.37-.86c.07-.32.07-.61.07-.93V4.51c0-.44-.23-.7-.63-.67z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
