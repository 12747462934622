import { useState, useEffect, useMemo } from "react";
import { useWindowDimensions } from "react-native";

import { AnchorProvider, Wallet } from "@coral-xyz/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSharedValue } from "react-native-reanimated";

import { ModalSheet } from "@showtime-xyz/universal.modal-sheet";
import { View } from "@showtime-xyz/universal.view";

import { NavigationElementsProvider } from "app/navigation/navigation-elements-context";
import { PrimaryFont } from "app/profile/fonts/primary-font";
import { SecondaryFont } from "app/profile/fonts/secondary-font";
import { useConfigStore } from "app/stores/config-store";
import {
  DEFAULT_DRAWER_WIDTH,
  useNavigationStore,
} from "app/stores/navigation-store";
import { useWalletStore } from "app/stores/wallet-store";

const FontByLocale = (Font: any, localeKey: string) => {
  return (Font[localeKey] || Font[Object.keys(Font)[0]]).map(
    (f: any) => f.variable
  );
};

export function NavigationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [fontMain, localeKey, setIsClient, setHeight, setWidth] =
    useConfigStore((state) => [
      state.fontMain,
      state.localeKey,
      state.setIsClient,
      state.setHeight,
      state.setWidth,
    ]);
  const [modal, hideModal, sheet, hideSheet, setMobileDrawerOffet] =
    useNavigationStore((state) => [
      state.modal,
      state.hideModal,
      state.sheet,
      state.hideSheet,
      state.setMobileDrawerOffet,
    ]);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [isTabBarHidden, setIsTabBarHidden] = useState(false);

  const { height, width } = useWindowDimensions();
  const mobileDrawerOffset = useSharedValue(-DEFAULT_DRAWER_WIDTH);

  const [connection, setOpenBookClient] = useWalletStore((state) => [
    state.connection,
    state.setOpenBookClient,
  ]);

  const { publicKey, signTransaction, signAllTransactions } = useWallet();
  const wallet = { publicKey, signTransaction, signAllTransactions } as Wallet;

  const fonts = useMemo(() => {
    return [
      ...FontByLocale(PrimaryFont, localeKey),
      ...FontByLocale(SecondaryFont, localeKey),
    ].join(" ");
  }, []);

  useEffect(() => {
    setMobileDrawerOffet(mobileDrawerOffset);
    setHeight(height);
    setWidth(width);
    setIsClient(true);
  }, []);

  useEffect(() => {
    const anchorProvider = new AnchorProvider(connection, wallet, {
      commitment: "confirmed",
    });
    setOpenBookClient(anchorProvider);
  }, [connection, wallet, setOpenBookClient]);

  return (
    <NavigationElementsProvider
      value={{
        isHeaderHidden,
        setIsHeaderHidden,
        isTabBarHidden,
        setIsTabBarHidden,
      }}
    >
      {children}
      <ModalSheet visible={!!modal} onClose={hideModal} title={modal?.title}>
        <div className={fonts}>
          <View style={fontMain}>{modal?.component}</View>
        </div>
      </ModalSheet>
    </NavigationElementsProvider>
  );
}
