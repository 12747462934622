import { useIsDarkMode } from "@showtime-xyz/universal.hooks";
import { Image } from "design-system";

type BrandLogoProps = {
  color?: string;
  size?: number;
};

const BrandLogo = ({ color = "#fff", size = 84 }: BrandLogoProps) => {
  const isDark = useIsDarkMode();

  return (
    <Image
      source={{ uri: `/assets/logo-${isDark ? "dark" : "light"}.png` }}
      style={{ height: size, width: size }}
      height={size}
      width={size * (84 / 16)}
    />
  );
};

export { BrandLogo };
