import { Connection, Cluster } from "@solana/web3.js";

import { DEVNET_CLUSTER, DEFAULT_CLUSTER } from "app/constants/web3";

// const DEVNET_RPC = "https://api.devnet.solana.com";
const DEVNET_RPC =
  "https://devnet.helius-rpc.com/?api-key=77e32712-6f5c-44db-a0e5-8b36463983eb";

const RPCs = [
  // nada wallets
  "https://mainnet.helius-rpc.com/?api-key=48ed2e8d-e903-498b-8c00-b35c63e423cc",
  "https://mainnet.helius-rpc.com/?api-key=7edca6e3-d9f3-461a-a757-659c3fb35bbf",
  "https://mainnet.helius-rpc.com/?api-key=6a882e13-18d8-4314-84b1-c13401210f33",
  "https://mainnet.helius-rpc.com/?api-key=cb45f8e7-998e-4464-aa7b-9346f637e5fa",
  "https://mainnet.helius-rpc.com/?api-key=f9b15984-14df-4e97-ae02-e87f26a9d89e",
  "https://mainnet.helius-rpc.com/?api-key=dad22a38-8cd5-4d7c-98e7-70ab3b1bb5c6",
  // other wallets
  "https://mainnet.helius-rpc.com/?api-key=127e61a0-7725-489f-947e-1a591a32d537",
  "https://mainnet.helius-rpc.com/?api-key=77e32712-6f5c-44db-a0e5-8b36463983eb",
  // "https://mainnet.helius-rpc.com/?api-key=1fac4c07-66c6-49d4-84a5-18f4f41358e1",
  "https://mainnet.helius-rpc.com/?api-key=5a9ff7d8-4faf-4140-b986-dbca015be6fd",
  "https://mainnet.helius-rpc.com/?api-key=32e824ee-0c9c-447a-b948-61e71c9810f5",
  "https://mainnet.helius-rpc.com/?api-key=54a33f03-b404-4bd1-a614-b6572ea349e7",
  "https://mainnet.helius-rpc.com/?api-key=4d84e389-8fbc-494c-951a-79f8142b1eff",
  "https://mainnet.helius-rpc.com/?api-key=0fbc8757-86e9-48d3-8ec0-55a038ced2b1",

  // "https://crimson-ancient-field.solana-mainnet.quiknode.pro/8ba866ce0fa36277f86963f9a38b424c8903052d/",

  // "https://solana-mainnet.g.alchemy.com/v2/47DC3TmUQM-TcxEXOAws5RIQEAGUrNIo",

  // "https://dark-quick-knowledge.solana-mainnet.quiknode.pro/b66e298ddcc244764f45f82725b992840a7d48cb/",
  // "https://mainnet.helius-rpc.com/?api-key=2b0de636-3816-477b-a046-4ff455c3e7a7",

  // "https://rpc.shyft.to?api_key=vlzIYGrayJmgWsFl",

  // "https://alien-warmhearted-sun.solana-mainnet.quiknode.pro/80123d54e12cfdc1caa65cd648992364e46822b6/",
  // "https://white-flashy-owl.solana-mainnet.quiknode.pro/83f7d856ecf0536f32d8495a23982dd80d4287bb/",
  // "https://small-special-lambo.solana-mainnet.quiknode.pro/2d44f191a9f0e0c7c18a6360cbd6c2d595699dbc/",
  // "https://solemn-wiser-hill.solana-mainnet.quiknode.pro/61bcfcc39690eb28f34c4acba2b43da7772d3996/",
  // "https://solemn-summer-tab.solana-mainnet.quiknode.pro/8971931a7b6da67a4d7814666c6915aed9a27b4c/",
  // "https://greatest-blue-theorem.solana-mainnet.quiknode.pro/6d2c4d3d77eb87698218ffafdeb12e4982a831b8/",
];

const randomRPC = () => {
  return RPCs[Math.floor(Math.random() * RPCs.length)];
};

const solConnection = (cluster: Cluster = DEFAULT_CLUSTER) => {
  const rpc = cluster == DEVNET_CLUSTER ? DEVNET_RPC : randomRPC();

  return new Connection(rpc, {
    commitment: "finalized",
  });
};

const shorthandAddress = (pk: any, length: number = 4) => {
  const address = typeof pk == "string" ? pk : pk.toBase58();
  if (!address) {
    return "";
  }
  return `${address.substr(0, length)}...${address.substr(
    address.length - length,
    length
  )}`;
};

export { randomRPC, solConnection, shorthandAddress };
