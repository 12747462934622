import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgGiftV2 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      fillRule="evenodd"
      d="M6.983 4.213a2.62 2.62 0 0 1 3.618-.802c.456.29.98.825 1.4 1.402.418-.577.943-1.112 1.399-1.402a2.62 2.62 0 0 1 3.664 3.543h1.517A2.92 2.92 0 0 1 21.5 9.873v.83c0 .666-.272 1.269-.71 1.704v4.428a4.149 4.149 0 0 1-4.149 4.149H7.36a4.149 4.149 0 0 1-4.149-4.149v-4.428a2.393 2.393 0 0 1-.71-1.704v-.83a2.92 2.92 0 0 1 2.92-2.92h1.517a2.62 2.62 0 0 1 .046-2.74Zm4.17 2.656a.797.797 0 0 0-.02-.106 2.564 2.564 0 0 0-.325-.696c-.352-.563-.838-1.054-1.116-1.23a.93.93 0 0 0-.999 1.568c.278.177.929.41 1.587.491.321.04.586.036.769 0a.795.795 0 0 0 .104-.027Zm1.694 0a.795.795 0 0 0 .105.027c.182.036.447.04.768 0 .659-.082 1.31-.314 1.587-.491a.93.93 0 0 0-.999-1.569c-.278.177-.764.668-1.116 1.23-.172.275-.28.516-.325.697a.78.78 0 0 0-.02.106Zm6.253 6.029h-6.255v6.396h3.796c1.358 0 2.459-1.101 2.459-2.459V12.898Zm-7.945 6.396v-6.396H4.9V16.835c0 1.358 1.1 2.459 2.459 2.459h3.796Zm8.444-8.086h-6.754V8.645h-1.69v2.563H4.4a.708.708 0 0 1-.21-.505v-.83c0-.679.55-1.23 1.228-1.23h13.162c.678 0 1.229.551 1.229 1.23v.83c0 .197-.08.376-.211.505Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgGiftV2;
