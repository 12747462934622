import { Platform } from "react-native";

import type { ImageSystemSymbolConfiguration } from "react-native-ios-context-menu/lib/typescript/types/ImageItemConfig";
import * as DropdownMenu from "zeego/src/dropdown-menu";
import type { MenuItemIconProps as ZeegoMenuItemIconProps } from "zeego/src/menu/types";

import { useIsDarkMode } from "@showtime-xyz/universal.hooks";
import { colors } from "@showtime-xyz/universal.tailwind";

import { DropdownMenuItemIcon } from "design-system/dropdown-menu";

import { FontAwesome } from "../fontawesome";

type MenuItemIconProps = Omit<ZeegoMenuItemIconProps, "ios"> & {
  icon: string;
  ios?: ImageSystemSymbolConfiguration & {
    name: any;
  };
};

export const MenuItemIcon = DropdownMenu.menuify(
  ({ icon, ...rest }: MenuItemIconProps) => {
    const isDark = useIsDarkMode();
    if (Platform.OS === "web") {
      return (
        <DropdownMenuItemIcon style={{ marginEnd: 8 }}>
          <FontAwesome
            color={isDark ? colors.neutral[200] : colors.gray[600]}
            icon={icon}
            fixedWidth
            {...rest}
          />
        </DropdownMenuItemIcon>
      );
    }
    return <DropdownMenu.ItemIcon {...rest} />;
  },
  "ItemIcon"
);
