import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

function SvgHot(props: SvgProps) {
  return (
    <Svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.467 0c1.102.018 5.555 2.549 6.386 8.558.905-.889 1.409-3.664 1.147-4.843 3.952 2.969 6 6.781 6 11.034C21 19.843 17.57 24 12.037 24 6.309 24 3 20.247 3 15.724 3 9.464 8.052 8.104 7.467 0zm3.262 19.743c-.749.848-.368 1.945.763 2.045 1.035.093 1.759-.812 2.032-1.792.273-.978.09-2.02-.369-2.893-.998 1.515-1.52 1.64-2.426 2.64zm4.42 1.608c2.49-1.146 3.852-3.683 3.852-6.58 0-2.358-.94-4.977-2.5-7.04C15.758 10.598 13.577 11.709 12 12c.05-3.219-.318-6.153-2.602-8.438C9.102 8.294 5.077 11.192 5 15.676c-.029 1.511.514 3.203 1.73 4.415a5.952 5.952 0 001.664 1.16 3.12 3.12 0 01.195-1.848c.911-2.106 3.333-2.321 4.202-5.754.952.749 3.275 3.503 2.778 6.358a5.39 5.39 0 01-.42 1.344z"
        fill={props.color}
      />
    </Svg>
  );
}

export default SvgHot;
