import { ComponentProps, forwardRef } from "react";
import {
  TextInput as ReactNativeTextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native";

import { styled } from "@showtime-xyz/universal.tailwind";
import type { TW } from "@showtime-xyz/universal.tailwind";

export type TextInputProps = { tw?: TW } & ComponentProps<
  typeof ReactNativeTextInput
>;

const StyledTextInput = styled(ReactNativeTextInput);

const TextInput = forwardRef<typeof ReactNativeTextInput, TextInputProps>(
  ({ tw, ...props }, ref: any) => (
    <StyledTextInput
      {...props}
      tw={Array.isArray(tw) ? tw.join(" ") : tw}
      ref={ref}
      onChangeText={(text: string) => {
        let newText: string = text;

        if (["decimal", "numeric"].includes(props.inputMode as string)) {
          const regex = /[\-?\d|,|.|e|E|\+]+/g;
          const string = text;
          const matches = string.match(regex);
          newText = matches ? matches[0] : "";
        }

        props?.onChangeText?.(newText);
      }}
    />
  )
);

TextInput.displayName = "TextInput";

export { TextInput, NativeSyntheticEvent, TextInputKeyPressEventData };
