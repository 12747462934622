import { useIsDarkMode } from "@showtime-xyz/universal.hooks";
import { Image } from "design-system";

type BrandIconProps = {
  color?: string;
  size?: number;
};

export const BrandIcon = ({ color = "#fff", size = 36 }: BrandIconProps) => {
  const isDark = useIsDarkMode();

  return (
    <Image
      source={{ uri: `/assets/icon-${isDark ? "dark" : "light"}.png` }}
      style={{ height: size, width: size }}
      height={size}
      width={size}
    />
  );
};
