import { useEffect, useState } from "react";

const LOCALE_KEY_STRING = "locale";
let listeners: any = [];

export function setLocaleKey(key: string) {
  if (typeof window == "undefined") {
    return;
  }

  localStorage.setItem(LOCALE_KEY_STRING, key);
  listeners.forEach((listener: any) => listener(key));

  const d = new Date();
  const exdays = 365 * 2;
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = LOCALE_KEY_STRING + "=" + key + ";" + expires + ";path=/";
}

export function getLocaleKey(): string {
  if (typeof window == "undefined") {
    return "";
  }

  return (
    localStorage.getItem(LOCALE_KEY_STRING) || getCookie(LOCALE_KEY_STRING)
  );
}

export function deleteLocaleKey() {
  if (typeof window == "undefined") {
    return;
  }

  localStorage.removeItem(LOCALE_KEY_STRING);
  document.cookie =
    LOCALE_KEY_STRING + "=; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
}

export function useLocaleKey() {
  const [, setCount] = useState(0);
  useEffect(() => {
    const callback = () => {
      setCount((c) => c + 1);
    };
    listeners.push(callback);
    return () => {
      listeners = listeners.filter((l: any) => l !== callback);
    };
  }, []);

  return getLocaleKey();
}

function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
