import { Divider } from "@showtime-xyz/universal.divider";
import { View } from "@showtime-xyz/universal.view";

import { Heading } from "app/components/commons/head";

const PageHeading = ({ label }: any) => {
  if (!label) {
    return null;
  }

  return (
    <View tw="mb-2 hidden md:block">
      <View tw="p-4">
        <Heading label={label} />
      </View>
      <Divider />
    </View>
  );
};

export { PageHeading };
