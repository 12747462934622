import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgTwitterOutlineCopy = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M2.978 17.494c-.096 0-.193-.002-.289-.006a.907.907 0 0 0-.9.631.893.893 0 0 0 .417 1.06 12.508 12.508 0 0 0 6.015 1.568.742.742 0 0 0 .07.003c8.037 0 12.424-6.573 12.424-12.244V8.35a9.022 9.022 0 0 0 1.573-1.58.89.89 0 0 0-.004-1.101.92.92 0 0 0-.484-.317c.07-.126.134-.256.193-.389a.884.884 0 0 0-.234-1.032.9.9 0 0 0-1.004-.113 7.564 7.564 0 0 1-1.684.637 4.906 4.906 0 0 0-6.544.092 4.761 4.761 0 0 0-1.533 3.581A10.947 10.947 0 0 1 4.1 4.413c-.386-.45-1.164-.436-1.476.165a4.737 4.737 0 0 0-.016 4.35.775.775 0 0 0-.558.749 4.76 4.76 0 0 0 1.715 3.653.85.85 0 0 0-.136.892 4.82 4.82 0 0 0 2.484 2.598 7.596 7.596 0 0 1-3.135.674Zm6.062 2.504Z"
      stroke={props.color}
      strokeWidth={2}
    />
  </Svg>
);

export default SvgTwitterOutlineCopy;
