import { Noto_Naskh_Arabic, Roboto } from "next/font/google";

const arRegular = Noto_Naskh_Arabic({
  subsets: ["arabic"],
  variable: "--font-primary",
});
const arBold = Noto_Naskh_Arabic({
  weight: "700",
  subsets: ["arabic"],
  variable: "--font-primary-bold",
});
const ar = [arRegular, arBold];

const enRegular = Roboto({
  weight: "300",
  subsets: ["latin"],
  variable: "--font-primary",
});
const enBold = Roboto({
  weight: "700",
  subsets: ["latin"],
  variable: "--font-primary-bold",
});
const en = [enRegular, enBold];

const PrimaryFont = {
  ar,
  en,
};

export { PrimaryFont };
