import * as React from "react";

import Svg, { Path, SvgProps } from "react-native-svg";

function Upload(props: SvgProps) {
  return (
    <Svg fill="none" viewBox="0 0 24 24" stroke={props.color} {...props}>
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </Svg>
  );
}

export default Upload;
