import { Dimensions } from "react-native";

import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { getLocaleKey, setLocaleKey } from "app/lib/locale-key";
import { locales, localesData } from "app/locale";
import config from "app/profile/config.json";

const { height, width } = Dimensions.get("window");

const localeKey = getLocaleKey() || config.defaultLocale;
const locale = locales[localeKey];
const localeData = localesData[localeKey];

const isRTLDirection = () => {
  return localeData.direction == "rtl";
};

const patchDocumentDirection = (localeKey: string) => {
  if (typeof document == "undefined") {
    return;
  }

  const html = document.getElementsByTagName("html")[0];
  html.setAttribute("lang", localeKey);
  html.setAttribute("dir", localesData[localeKey].direction);
};

patchDocumentDirection(localeKey);

const useConfigStore = createWithEqualityFn(
  (set: Function, get: Function) => ({
    fontMain: {
      // fontFamily: "__Cairo_bec0d4",
      direction: localeData.direction,
    },
    // themeColor: config.theme.color,
    // colors: colors[config.theme.color],
    textBalance: { textWrap: "balance" },
    nonSelectable: {
      WebkitTouchCallout: "none",
      WebkitUserSelect: "none",
      khtmlUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    },
    setLocale: (localeKey: string) => {
      set({
        localeKey,
        locale: locales[localeKey],
        isRTL: localesData[localeKey].direction == "rtl",
        fontMain: {
          ...get().fontMain,
          direction: localesData[localeKey].direction,
        },
      });
      setLocaleKey(localeKey);
      patchDocumentDirection(localeKey);
    },
    localeKey,
    locale,
    isRTL: isRTLDirection(),
    isClient: false,
    setIsClient: (isClient: boolean) => {
      set({ isClient });
    },
    height,
    setHeight: (height: number) => {
      set({ height });
    },
    width,
    setWidth: (width: number) => {
      set({ width });
    },
  }),
  shallow
);

export { useConfigStore, localeKey, isRTLDirection, localeData };
