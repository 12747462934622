import { useMemo, useState, useEffect, useCallback } from "react";

import { useRouter } from "next/router";
import Animated, { useAnimatedStyle } from "react-native-reanimated";

import { Divider } from "@showtime-xyz/universal.divider";
import { PressableScale } from "@showtime-xyz/universal.pressable-scale";
import { colors } from "@showtime-xyz/universal.tailwind";
import { Text } from "@showtime-xyz/universal.text";
import { View } from "@showtime-xyz/universal.view";

import { BrandLogo } from "app/components/brand-logo";
import { MenuItemIcon } from "app/components/dropdown/menu-item-icon";
import { FontAwesome } from "app/components/fontawesome";
import { useIsMd } from "app/hooks/use-content-width";
import { HEADER_HEIGHT } from "app/lib/constants";
import config from "app/profile/config.json";
import { useAppStore } from "app/stores/app-store";
import { useConfigStore } from "app/stores/config-store";
import {
  DEFAULT_DRAWER_WIDTH,
  useNavigationStore,
} from "app/stores/navigation-store";
import { useWalletStore } from "app/stores/wallet-store";
import { ConnectButton } from "app/web3/connect-button";

import {
  DropdownMenuContent,
  DropdownMenuItemTitle,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
} from "design-system/dropdown-menu";

import { Link, TextLink } from "./link";

const FONT_AWESOME_ICON_SIZE = 22;

const TokenItems = ({ focused }: any) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <View
          tw={[
            "hover:bg-blueGray-100 focus:bg-blueGray-100 mt-2 h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all hover:dark:bg-gray-900",
            focused ? "bg-blueGray-100 dark:bg-gray-800" : "",
          ].join(" ")}
        >
          <FontAwesome
            icon="fa-regular fa-money-bill"
            fontSize={FONT_AWESOME_ICON_SIZE}
            fixedWidth
          />
          <Text
            tw={[
              "font-primary mx-4 text-base text-black dark:text-white",
              focused ? "font-primary-bold" : "",
            ]}
          >
            Token Tools
          </Text>
        </View>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        style={{ minWidth: 150 }}
        disableBlurEffect
        side="bottom"
        sideOffset={0}
      >
        <DropdownMenuSub>
          <Link href="/create-token">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-sparkles" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Create Token
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/burn-token">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-fire" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Burn Token
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const CoinyMarketItems = ({ focused }: any) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <View
          tw={[
            "hover:bg-blueGray-100 focus:bg-blueGray-100 mt-2 h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all hover:dark:bg-gray-900",
            focused ? "bg-blueGray-100 dark:bg-gray-800" : "",
          ].join(" ")}
        >
          <FontAwesome
            icon="fa-regular fa-star-shooting"
            fontSize={FONT_AWESOME_ICON_SIZE}
            fixedWidth
          />
          <Text
            tw={[
              "font-primary mx-4 text-base text-black dark:text-white",
              focused ? "font-primary-bold" : "",
            ]}
          >
            Coiny Market
          </Text>
        </View>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        style={{ minWidth: 150 }}
        disableBlurEffect
        side="bottom"
        sideOffset={0}
      >
        <DropdownMenuSub>
          <Link href="/find-coiny-market">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-magnifying-glass" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Find Coiny Market
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/my-markets">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-shop" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  My Coiny Markets
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Divider />
          <View tw="h-[4px]" />
          <Link href="/create-coiny-market">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-sparkles" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Create Coiny Market
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const MarketItems = ({ focused }: any) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <View
          tw={[
            "hover:bg-blueGray-100 focus:bg-blueGray-100 mt-2 h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all hover:dark:bg-gray-900",
            focused ? "bg-blueGray-100 dark:bg-gray-800" : "",
          ].join(" ")}
        >
          <FontAwesome
            icon="fa-regular fa-shop"
            fontSize={FONT_AWESOME_ICON_SIZE}
            fixedWidth
          />
          <Text
            tw={[
              "font-primary mx-4 text-base text-black dark:text-white",
              focused ? "font-primary-bold" : "",
            ]}
          >
            Market Tools
          </Text>
        </View>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        style={{ minWidth: 150 }}
        disableBlurEffect
        side="bottom"
        sideOffset={0}
      >
        <DropdownMenuSub>
          <Link href="/find-market">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-magnifying-glass" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Find Market
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/my-markets">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-shop" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  My Markets
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Divider />
          <View tw="h-[4px]" />
          <Link href="/create-market">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-sparkles" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Create Market
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const LiquidityItems = ({ focused }: any) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <View
          tw={[
            "hover:bg-blueGray-100 focus:bg-blueGray-100 mt-2 h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all hover:dark:bg-gray-900",
            focused ? "bg-blueGray-100 dark:bg-gray-800" : "",
          ].join(" ")}
        >
          <FontAwesome
            icon="fa-regular fa-vault"
            fontSize={FONT_AWESOME_ICON_SIZE}
            fixedWidth
          />
          <Text
            tw={[
              "font-primary mx-4 text-base text-black dark:text-white",
              focused ? "font-primary-bold" : "",
            ]}
          >
            Liquidity Tools
          </Text>
        </View>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        style={{ minWidth: 150 }}
        disableBlurEffect
        side="bottom"
        sideOffset={0}
      >
        <DropdownMenuSub>
          <Link href="/find-liquidity-pool">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-magnifying-glass" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Find Liquidity Pool
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/my-liquidity-pools">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-vault" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  My Liquidity Pools
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Divider />
          <View tw="h-[4px]" />
          <Link href="/create-liquidity-pool">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-sparkles" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Create Liquidity Pool
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/add-liquidity">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-plus" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Add Liquidity
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
          <Link href="/remove-liquidity">
            <DropdownMenuSubTrigger key="nested-group-trigger-1">
              <View tw="flex-1 flex-row items-center">
                <MenuItemIcon icon="fa-regular fa-minus" />
                <DropdownMenuItemTitle tw="font-primary text-gray-700 dark:text-neutral-100">
                  Remove Liquidity
                </DropdownMenuItemTitle>
              </View>
            </DropdownMenuSubTrigger>
          </Link>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const MenuItem = ({
  focused,
  href,
  icon,
  title,
}: {
  focused?: boolean;
  href: string;
  icon: any;
  title: string;
}) => {
  return (
    <Link
      tw={[
        "mt-2 h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all hover:bg-blueGray-100 focus:bg-blueGray-100 hover:dark:bg-gray-900",
        focused ? "bg-blueGray-100 dark:bg-gray-800" : "",
      ].join(" ")}
      href={href}
    >
      <FontAwesome icon={icon} fontSize={FONT_AWESOME_ICON_SIZE} />
      <Text
        tw={[
          "font-primary mx-4 text-base text-black dark:text-white",
          focused ? "font-primary-bold" : "",
        ]}
      >
        {title}
      </Text>
    </Link>
  );
};

const Sidebar = ({}) => {
  const [locale, height] = useConfigStore((state) => [
    state.locale,
    state.height,
  ]);
  const [isAdmin] = useAppStore((state) => [state.isAdmin]);
  const [wallet] = useWalletStore((state) => [state.wallet]);
  const [mobileDrawerOffet, drawerWidth, setDrawerWidth, closeMobileDrawer] =
    useNavigationStore((state) => [
      state.mobileDrawerOffet,
      state.drawerWidth,
      state.setDrawerWidth,
      state.closeMobileDrawer,
    ]);
  const isMd = useIsMd();
  const router = useRouter();
  const [key, setKey] = useState([router.pathname, isMd].join("_"));
  const animatedStyles = useAnimatedStyle(() => {
    let translateX =
      mobileDrawerOffet?.value == undefined
        ? -DEFAULT_DRAWER_WIDTH
        : mobileDrawerOffet?.value;
    if (translateX > 0) {
      translateX = 0;
    }
    return {
      transform: [{ translateX }],
      zIndex: 150,
      position: "absolute",
      top: -HEADER_HEIGHT,
      height,
    };
  }, [mobileDrawerOffet]);

  const PUBLIC_ROUTES = useMemo(() => {
    let routes = [
      {
        title: locale.menu.home,
        key: "Home",
        icon: "fa-regular fa-home",
        pathname: "/",
        focused: router.pathname === "/",
        visible: true,
      },
      {
        title: "Faucet",
        key: "Faucet",
        icon: "fa-regular fa-faucet",
        pathname: "/faucet",
        focused: router.pathname === "/faucet",
        visible: true,
      },
      {
        title: locale.menu.createToken,
        key: "token",
        icon: "fa-regular fa-money-bill",
        pathname: "/create-token",
        focused: [
          "/create-token",
          "/burn-token",
          "/close-token-account",
        ].includes(router.pathname),
        visible: true,
      },
      // {
      //   title: locale.menu.createToken,
      //   key: "coinyMarket",
      //   icon: "fa-regular fa-star-shooting",
      //   pathname: "/create-coiny-market",
      //   focused: [
      //     "/create-coiny-market",
      //     "/find-coiny-market",
      //     "/my-coiny-markets",
      //   ].includes(router.pathname),
      //   visible: true,
      // },
      {
        title: "Market",
        key: "market",
        icon: "fa-regular fa-shop",
        pathname: "/create-market",
        focused: ["/create-market", "/find-market", "/my-markets"].includes(
          router.pathname
        ),
        visible: true,
      },
      {
        title: "Liquidity",
        key: "liquidity",
        icon: "fa-regular fa-chart-mixed",
        pathname: "/create-liquidity",
        focused: [
          "/create-liquidity-pool",
          "/find-liquidity-pool",
          "/add-liquidity",
          "/remove-liquidity",
          "/my-liquidity-pools",
        ].includes(router.pathname),
        visible: true,
      },
      // {
      //   title: "Create CLMM",
      //   key: "Create CLMM",
      //   icon: "fa-regular fa-cart-shopping-fast",
      //   pathname: "/create-clmm-pool",
      //   focused: router.pathname === "/create-clmm-pool",
      //   visible: true,
      // },
    ];

    return routes.filter((item) => !!item?.visible);
  }, [router.pathname, locale, wallet]);

  const WEB_ADMIN_ROUTES = useMemo(() => {
    if (!isAdmin) {
      return [];
    }

    return [
      {
        title: "Admin",
        key: "NewTokens",
        icon: "fa-regular fa-screwdriver-wrench",
        pathname: "/admin",
        focused: router.pathname === "/admin",
        visible: true,
      },
      {
        title: "Smithii Radar",
        key: "SmithiiRadar",
        icon: "fa-regular fa-radar",
        pathname: "/admin/smithii-radar",
        focused: router.pathname === "/admin/smithii-radar",
        visible: true,
      },
    ];
  }, [router.pathname, locale, isAdmin]);

  const ADMIN_ROUTES = useMemo(() => {
    if (!wallet) {
      return [];
    }

    const routes = [
      {
        title: locale.menu.newTokens,
        key: "NewTokens",
        icon: "fa-regular fa-sparkles",
        pathname: "/new-tokens",
        focused: router.pathname === "/new-tokens",
        visible: true,
      },
      {
        title: locale.menu.greens,
        key: "Greens",
        icon: "fa-regular fa-chart-simple",
        pathname: "/greens",
        focused: router.pathname === "/greens",
        visible: true,
      },
      {
        title: locale.menu.recommendations,
        key: "Recommendations",
        icon: "fa-regular fa-seedling",
        pathname: "/recommendations",
        focused: router.pathname === "/recommendations",
        visible: true,
      },
    ];

    return routes.filter((item) => !!item?.visible);
  }, [router.pathname, locale, wallet]);

  const onLayout = useCallback(
    ({ nativeEvent }: any) => {
      if (drawerWidth > nativeEvent.layout.width) {
        return;
      }
      setDrawerWidth(nativeEvent.layout.width);
    },
    [drawerWidth]
  );

  useEffect(() => {
    const newKey = [router.pathname, isMd].join("_");
    closeMobileDrawer();
    if (key == newKey) {
      return;
    }
    setKey(newKey);
  }, [router.pathname, isMd, key]);

  return (
    <Animated.View
      style={isMd ? {} : animatedStyles}
      key={key}
      onLayout={onLayout}
    >
      <View tw="border-t-blueGray-100 border-t bg-white p-2 shadow-md h-full">
        <View>
          <PressableScale tw="md:hidden" onPress={closeMobileDrawer}>
            <FontAwesome
              icon="fa-regular fa-times"
              fontSize={FONT_AWESOME_ICON_SIZE}
              fixedWidth
            />
          </PressableScale>
          <View tw="mb-4">
            <Link href="/" tw="flex-row items-center pt-8">
              <BrandLogo />
            </Link>
          </View>
          <View tw="mt-5 w-48 justify-center">
            <ConnectButton />
            {PUBLIC_ROUTES.map((item) => {
              switch (item.key) {
                case "token":
                  return <TokenItems {...item} />;
                case "coinyMarket":
                  return <CoinyMarketItems {...item} />;
                case "market":
                  return <MarketItems {...item} />;
                case "liquidity":
                  return <LiquidityItems {...item} />;
                default:
                  return (
                    <MenuItem
                      focused={item.focused}
                      href={item.pathname}
                      icon={item.icon}
                      title={item.title}
                      key={item.pathname}
                    />
                  );
              }
            })}

            {WEB_ADMIN_ROUTES.length ? (
              <View tw="mt-2">
                <Divider />
              </View>
            ) : null}
            {WEB_ADMIN_ROUTES.map((item) => {
              return (
                <MenuItem
                  focused={item.focused}
                  href={item.pathname}
                  icon={item.icon}
                  title={item.title}
                  key={item.pathname}
                />
              );
            })}

            {ADMIN_ROUTES.length ? (
              <View tw="mt-2">
                <Divider />
              </View>
            ) : null}

            {ADMIN_ROUTES.map((item) => {
              return (
                <MenuItem
                  focused={item.focused}
                  href={item.pathname}
                  icon={item.icon}
                  title={item.title}
                  key={item.pathname}
                />
              );
            })}
          </View>
        </View>

        <View
          tw={[
            "absolute bottom-0 mt-6 mb-2 inline-block",
            // !isAuthenticated || height > 880 ? "absolute" : "relative",
          ]}
          style={{}}
        >
          <View tw="inline-block">
            {[].map((item) => (
              <TextLink
                href={item.link}
                // target="_blank"
                tw="font-primary text-xs text-gray-500 dark:text-gray-300"
                key={item.title}
              >
                {item.title}
                {` · `}
              </TextLink>
            ))}
          </View>
          <Text tw="font-primary text-xs text-gray-500 dark:text-gray-300">
            © 2024 {config.name}
          </Text>
          <View tw="mt-2 inline-block w-full">
            {config.socials.map((item) => (
              <Link
                href={item.link}
                hrefAttrs={{
                  target: "_blank",
                  rel: "noreferrer",
                }}
                key={item.title}
                tw="inline-block w-1/4"
              >
                <FontAwesome
                  icon={item.icon}
                  color={colors.gray[500]}
                  fontSize={16}
                />
              </Link>
            ))}
          </View>
        </View>
      </View>
    </Animated.View>
  );
};

export { Sidebar, FONT_AWESOME_ICON_SIZE };
