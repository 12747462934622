import { Text } from "@showtime-xyz/universal.text";
import { View } from "@showtime-xyz/universal.view";

const Error404 = () => {
  return (
    <View tw="flex-1 items-center justify-center">
      <View tw="flex-row items-center">
        <Text tw="font-primary-bold text-2xl">404</Text>
        <View tw="mx-2 h-8 w-[2px] bg-black" />
        <Text tw="text-lg">This page could not be found.</Text>
      </View>
    </View>
  );
};

export { Error404 };
