import { OpenBookV2Client } from "@openbook-dex/openbook-v2";
import {
  MAINNET_PROGRAM_ID,
  DEVNET_PROGRAM_ID,
  LOOKUP_TABLE_CACHE,
} from "@raydium-io/raydium-sdk";
import { Raydium } from "@raydium-io/raydium-sdk-v2";
import { Cluster, PublicKey, Connection } from "@solana/web3.js";
import { MMKV } from "react-native-mmkv";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { toast } from "@showtime-xyz/universal.toast";

import { DEFAULT_CLUSTER, DEVNET_CLUSTER } from "app/constants/web3";
import { solConnection } from "app/lib/utilities/wallet";

const walletStorage = new MMKV();
const isClient = typeof window != "undefined";

const DEVNET_LUT = new PublicKey(
  "GT4M7wVf5JGVA6jMfsdKVFwrHa2SD1CB8PKZosdVJYbm"
);
const MAINNET_LUT = new PublicKey(
  "B3QuU3Cdzp1kjCHqJVzhjKQWv8AVfMmHJEUqEYz93cex"
);

const parseWallet = () => {
  let wallet = isClient ? walletStorage.getString("wallet") : null;

  if (wallet) {
    return JSON.parse(wallet) || null;
  } else {
    return null;
  }
};

const getProgramId = (cluster: Cluster = DEFAULT_CLUSTER) => {
  const ids =
    cluster == DEVNET_CLUSTER ? DEVNET_PROGRAM_ID : MAINNET_PROGRAM_ID;
  const FEE_DESTINATION =
    cluster == DEVNET_CLUSTER
      ? new PublicKey("3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR")
      : new PublicKey("7YttLkHDoNj9wyDur5pM1ejNaAvT9X4eqaYcHQqtj2G5");

  const lookupTableCache =
    cluster == DEVNET_CLUSTER ? undefined : LOOKUP_TABLE_CACHE;

  return {
    ...ids,
    FEE_DESTINATION,
    LOOKUP_TABLE_CACHE: lookupTableCache,
    OPENBOOK_MARKET_V2: new PublicKey(
      "opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb"
    ),
  };
};

const getLUT = (cluster: Cluster = DEFAULT_CLUSTER) => {
  return cluster == DEVNET_CLUSTER ? DEVNET_LUT : MAINNET_LUT;
};

export type WalletStore = any & {
  PROGRAM_ID: { [key: string]: PublicKey };
  connection: Connection;
};

const useWalletStore = createWithEqualityFn(
  (set: Function, get: Function) =>
    ({
      publicKey: null,
      setPublicKey: (publicKey: PublicKey) => {
        set({ publicKey });
      },
      cluster: DEFAULT_CLUSTER,
      setCluster: (cluster: Cluster) => {
        const connection = solConnection(cluster);

        set({
          cluster,
          connection,
          programId: getProgramId(cluster),
          LUT_ADDRESS: getLUT(cluster),
        });
        walletStorage.set("cluster", cluster);
      },
      connection: solConnection(DEFAULT_CLUSTER),
      irysSol: () => {},
      setIrysSol: (irysSol: any) => {
        set({ irysSol });
      },

      PROGRAM_ID: getProgramId(DEFAULT_CLUSTER),
      LUT_ADDRESS: getLUT(DEFAULT_CLUSTER),

      wallet: parseWallet(),

      raydiumClient: null,
      setRaydiumClient: async ({ signAllTransactions, publicKey }: any) => {
        if (!signAllTransactions || !publicKey) {
          return;
        }
        const raydiumClient = await Raydium.load({
          signAllTransactions,
          // owner: publicKey,
          connection: get().connection,
          urlConfigs: {
            // ...urlConfigs,
            // BASE_HOST: !isProdEnv() ? getStorageItem('_r_api_host_') || urlConfigs.BASE_HOST : urlConfigs.BASE_HOST,
            JUP_TOKEN_LIST: "/api/jup-list",
          },
          disableFeatureCheck: true,
          blockhashCommitment: "finalized",
        });

        set({ raydiumClient });
      },

      openBookClient: null,
      setOpenBookClient: (anchorProvider: any) => {
        const programId = get().PROGRAM_ID.OPENBOOK_MARKET_V2;
        const openBookClient = new OpenBookV2Client(anchorProvider, programId);
        set({ openBookClient });
      },

      changeWallet: (wallet: any) => {
        fetch("/api/wallet", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(wallet),
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.error) {
              toast.error(resp.error);
              return;
            }
            set({ wallet });
            walletStorage.set("wallet", JSON.stringify(wallet));
            toast.success("Wallet changed");
          })
          .catch((error: any) => {
            console.log("error", error);
          });
      },

      // External links
      solanaExplorerAccountUrl: (publicKey: string) => {
        return `https://explorer.solana.com/account/${publicKey}?cluster=${
          get().cluster
        }`;
      },
      solanaExplorerTxkUrl: (signature: string) => {
        return `https://explorer.solana.com/tx/${signature}?cluster=${
          get().cluster
        }`;
      },
      solanaExplorerBlockUrl: (block: string) => {
        return `https://explorer.solana.com/block/${block}?cluster=${
          get().cluster
        }`;
      },
    } as WalletStore),
  shallow
);

export { useWalletStore, parseWallet };
