import "raf/polyfill";

import "setimmediate";

import { useEffect, useMemo } from "react";

import "@coiny/next/libs/fontawesome";
import "@coiny/next/styles/styles.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { AppProps } from "next/app";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import "react-datepicker/dist/react-datepicker.css";

import { View } from "@showtime-xyz/universal.view";

import Header from "app/components/header";
import { withColorScheme } from "app/components/memo-with-theme";
import { APP_NAME, WEB_DOMAIN } from "app/lib/constants";
import * as jsExtentions from "app/lib/js-extensions";
import { Sentry } from "app/lib/sentry";
import { PageHeading } from "app/navigation/page-heading";
import { Sidebar } from "app/navigation/sidebar";
import { PrimaryFont } from "app/profile/fonts/primary-font";
import { SecondaryFont } from "app/profile/fonts/secondary-font";
import { AppProviders } from "app/providers/app-providers";
import { Error404 } from "app/screens/error/404";
import { useConfigStore } from "app/stores/config-store";
import { prevRouteRef } from "app/utilities";

import { Toaster } from "design-system/toast";

jsExtentions;

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_SERVER_ENVIRONMENT,
});

function App({ Component, pageProps, router }: AppProps) {
  const meta = pageProps.meta;

  // const scriptLoadedRes = useScript(
  //   "https://js-cdn.music.apple.com/musickit/v3/musickit.js"
  // );

  // useEffect(() => {
  //   if (scriptLoadedRes === "ready") {
  //     initialiseAppleMusic();
  //   }
  // }, [scriptLoadedRes]);

  useEffect(() => {
    function setPrevRoute() {
      prevRouteRef.current = router.asPath;
    }
    router.events?.on("routeChangeStart", setPrevRoute);
    return () => {
      router.events?.off("routeChangeStart", setPrevRoute);
    };
  }, [router]);

  const metaTags = meta ? (
    <>
      <title>{`${
        meta.title ? meta.title + " | " + APP_NAME : APP_NAME
      }`}</title>
      <meta key="title" name="title" content={meta.title} />

      <meta name="description" content={meta.description} />

      {/* Open graph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <meta name="twitter:app:name:iphone" content={APP_NAME} />
      {/* <meta name="twitter:app:id:iphone" content="1606611688" /> */}

      {meta.deeplinkUrl && (
        <meta
          name="twitter:app:url:iphone"
          content={"com.nabot://" + meta.deeplinkUrl}
        />
      )}

      <meta name="twitter:app:name:ipad" content={APP_NAME} />
      {/* <meta name="twitter:app:id:ipad" content="1606611688" /> */}

      <meta name="twitter:app:name:googleplay" content={APP_NAME} />
      {/* <meta name="twitter:app:id:googleplay" content="io.showtime" /> */}
      {meta.deeplinkUrl && (
        <meta
          name="twitter:app:url:googleplay"
          content={`${WEB_DOMAIN}/${meta.deeplinkUrl}`}
        />
      )}
    </>
  ) : (
    <>
      <title>{APP_NAME}</title>
      <meta key="title" name="title" content="Showtime" />
    </>
  );

  const isDraftScreen = pageProps.isDraftScreen;
  const title = pageProps?.meta?.title;
  const disableTitle = pageProps?.meta?.disableTitle;

  return (
    <>
      <Head>
        {metaTags}

        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="/icons/icon-512x512.png"
        />

        <meta name="application-name" content="Showtime" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Showtime" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#000000" />
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
          name="viewport"
        />
      </Head>
      <AppProviders>
        <Container>
          <NextNProgress
            color="#4F46E5"
            options={{ showSpinner: false }}
            showOnShallow={false}
          />
          <Header title={title} />
          <View tw="flex-1 md:flex-row">
            <Sidebar />
            <View tw={["flex-1 md:mx-2 md:mt-4", !isDraftScreen && "bg-white"]}>
              {!isDraftScreen && !disableTitle && <PageHeading label={title} />}
              <View
                tw={
                  isDraftScreen
                    ? ""
                    : pageProps.notFound
                    ? "flex-1"
                    : "p-2 md:p-4"
                }
              >
                {pageProps.notFound ? (
                  <Error404 />
                ) : (
                  <Component {...pageProps} />
                )}
              </View>
            </View>
          </View>
        </Container>

        <Toaster />
      </AppProviders>
    </>
  );
}

const FontByLocale = (Font: any, localeKey: string) => {
  return (Font[localeKey] || Font[Object.keys(Font)[0]]).map(
    (f: any) => f.variable
  );
};

const Container = withColorScheme(
  ({ children }: { children: React.ReactNode }) => {
    const [localeKey] = useConfigStore((state) => [state.localeKey]);

    const fonts = useMemo(() => {
      return [
        ...FontByLocale(PrimaryFont, localeKey),
        ...FontByLocale(SecondaryFont, localeKey),
      ].join(" ");
    }, []);

    return (
      <div className={fonts}>
        <View
          tw="bg-coolGray-50 min-h-screen w-full flex-1"
          // @ts-ignore
          style={
            {
              // paddingTop: headerHeight,
              // paddingBottom: `calc(${bottomBarHeight}px + env(safe-area-inset-bottom))`,
            }
          }
        >
          {children}
        </View>
      </div>
    );
  }
);

export default App;
