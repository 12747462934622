import { useIsDarkMode } from "@showtime-xyz/universal.hooks";
import { ArrowLeft, Showtime } from "@showtime-xyz/universal.icon";
import { PressableScale } from "@showtime-xyz/universal.pressable-scale";
import { useRouter } from "@showtime-xyz/universal.router";
import { View } from "@showtime-xyz/universal.view";
import { useConfigStore } from "app/stores/config-store";
import { Platform } from "react-native";

import { BrandIcon } from "../brand-icon";
import { FontAwesome } from "../fontawesome";

type HeaderStartProps = {
  canGoBack?: boolean;
  withBackground?: boolean;
  color?: string;
};
export const HeaderStart = ({
  canGoBack,
  withBackground = false,
  color,
}: HeaderStartProps) => {
  const [isRTL] = useConfigStore((state) => [state.isRTL]);
  const isDark = useIsDarkMode();
  const router = useRouter();
  const isHome = router.pathname === "/";
  const isShowShowtimeIcon =
    Platform.OS === "web" &&
    (router.pathname === "/" ||
      router.pathname === "/channels" ||
      router.pathname === "/notifications");

  const ShowBrandIcon = Platform.select({
    default: !canGoBack ? false : true,
    web: !canGoBack ? ArrowLeft : isShowShowtimeIcon ? true : false,
  });

  return (
    <PressableScale
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      style={[
        {
          justifyContent: "center",
          alignItems: "center",
        },
      ]}
      onPress={() => {
        if (isHome) {
          router.push("/");
        } else if (Platform.OS === "web") {
          if (history?.length > 1) {
            router.back();
          } else {
            router.push("/");
          }
        } else {
          router.back();
        }
      }}
    >
      {isHome || ShowBrandIcon ? (
        <View
          tw="items-center justify-center"
          style={{ width: 48, height: 48 }}
        >
          <View>
            <BrandIcon />
          </View>
        </View>
      ) : (
        <View
          tw="h-7 w-7 items-center justify-center rounded-full"
          style={[
            withBackground && { backgroundColor: "rgba(0,0,0,.6)" },
            { width: 48 },
          ]}
        >
          <FontAwesome
            icon={
              isRTL ? "fa-regular fa-arrow-right" : "fa-regular fa-arrow-left"
            }
            color={
              color ? color : withBackground ? "#FFF" : isDark ? "#FFF" : "#000"
            }
            fontSize={24}
          />
        </View>
      )}
    </PressableScale>
  );
};
