import { useContext, useCallback } from "react";
import { FlatList } from "react-native";

import { useWallet } from "@solana/wallet-adapter-react";

import { Image } from "@showtime-xyz/universal.image";
import { Pressable } from "@showtime-xyz/universal.pressable";
import { Text } from "@showtime-xyz/universal.text";

import { Web3Context } from "./provider";

const WalletsLister = ({ onWalletPressed }: any) => {
  const { installedWallets } = useContext(Web3Context);
  const { select } = useWallet();

  const renderItem = useCallback(
    ({ item: wallet }: any) => {
      return (
        <Pressable
          key={wallet.name}
          tw="my-1 bg-blueGray-50 hover:bg-blueGray-100 rounded-xl flex-row items-center justify-between p-4 border border-blueGray-200"
          onPress={() => {
            select(wallet.name);
            onWalletPressed?.(wallet);
          }}
        >
          <Image source={{ uri: wallet.icon }} width={40} height={40} />
          <Text tw="font-primary text-base ms-2 flex-1">{wallet.name}</Text>
        </Pressable>
      );
    },
    [installedWallets]
  );

  return <FlatList data={installedWallets} renderItem={renderItem} />;
};

export { WalletsLister };
