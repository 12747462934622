import config from "app/profile/config.json";

export const APP_NAME = config.name;

export const WEB_DOMAIN = __DEV__ ? "http://localhost:3000" : config.site;

export const APP_SERVER = __DEV__
  ? "http://localhost:3000"
  : "http://localhost:3000";

export const BOTTOM_TABBAR_BASE_HEIGHT = 64;

export const SWIPE_LIST_SCREENS = [
  "/foryou",
  "/profile/[username]/[dropSlug]",
  "/list",
];

export const HIDE_MOBILE_WEB_HEADER_SCREENS = [
  "/foryou",
  "/channels/[channelId]",
  "/profile/[username]/[dropSlug]",
  "/profile/[username]",
  "/list",
  "/search",
];
export const HIDE_MOBILE_WEB_FOOTER_SCREENS = ["/channels/[channelId]"];

export const DARK_THEME_PATHNAMES = ["/", "/foryou"];

export const WEBSOCKET_PORT = 3005;
export const WEBSOCKET_URL =
  __DEV__ || true ? `http://localhost:${WEBSOCKET_PORT}` : config.socket;

export const HEADER_HEIGHT = 48;
