import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

const FontAwesome = (props: FontAwesomeIconProps) => {
  return <FontAwesomeIcon {...props} />;
};

export { FontAwesome };
