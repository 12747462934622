import { useCallback, useEffect, createContext, useMemo } from "react";

import { WebIrys } from "@irys/sdk";
import {
  ConnectionProvider,
  WalletProvider,
  useWallet,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  UnsafeBurnerWalletAdapter,
  CloverWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
  TrustWalletAdapter,
  CoinbaseWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

import { DEVNET_CLUSTER, MAINNET_CLUSTER } from "app/constants/web3";
import { randomRPC } from "app/lib/utilities/wallet";
import { useAppStore } from "app/stores/app-store";
import { useWalletStore } from "app/stores/wallet-store";

const WalletContext = createContext<any>({});
const Web3Context = createContext<any>({});

const getIrysSol = async (
  network: string,
  provider: any
): Promise<WebIrys | null> => {
  if (!window.solana || !provider?.publicKey) {
    return null;
  }
  await window.solana.connect();
  await provider.connect();

  let wallet: any = { name: "solana", provider };
  if (network === DEVNET_CLUSTER) {
    wallet = {
      ...wallet,
      rpcUrl: clusterApiUrl(DEVNET_CLUSTER),
    };
  } else {
    wallet = {
      ...wallet,
      rpcUrl: randomRPC(),
    };
  }

  if (network === MAINNET_CLUSTER) {
    network = "mainnet";
  }

  const webIrys = new WebIrys({
    network,
    token: "solana",
    wallet,
  });

  await webIrys.ready();

  return webIrys;
};

const IrysWrapper = ({ children }: any) => {
  const provider = useWallet();
  const [setIsAdmin, fetchPriorityFeeAct] = useAppStore((state) => [
    state.setIsAdmin,
    state.fetchPriorityFeeAct,
  ]);
  const [connection, cluster, setCluster, setIrysSol, setRaydiumClient] =
    useWalletStore((state: any) => [
      state.connection,
      state.cluster,
      state.setCluster,
      state.setIrysSol,
      state.setRaydiumClient,
    ]);

  useEffect(() => {
    fetchPriorityFeeAct();
    setCluster(MAINNET_CLUSTER);
    // setCluster(cluster);
  }, []);

  useEffect(() => {
    setIsAdmin(provider.publicKey);
  }, [provider.publicKey]);

  useEffect(() => {
    setRaydiumClient({
      signAllTransactions: provider.signAllTransactions,
      publicKey: provider.publicKey,
    });
  }, [provider]);

  useEffect(() => {
    getIrysSol(cluster, provider).then((ir) => {
      setIrysSol(ir);
    });
  }, [cluster, provider]);

  const sendTransaction = useCallback(
    async (transaction: any, configs: any = {}) => {
      if (!provider?.signTransaction || !connection) {
        return;
      }

      const latestBlockhash = {
        blockhash: transaction.blockhash,
        lastValidBlockHeight: transaction.lastValidBlockHeight,
      };

      let signature = null;
      if (cluster == DEVNET_CLUSTER) {
        const signedTx = await provider.signTransaction(transaction);
        signature = await connection.sendRawTransaction(signedTx.serialize(), {
          skipPreflight: true,
        });
      } else {
        signature = await provider.sendTransaction(transaction, connection);
      }
      console.log("signature", signature);
      if (configs.onSend) {
        configs.onSend(signature);
      }

      const confirmation = await connection.confirmTransaction(
        {
          signature,
          // blockhash: transaction.blockhash,
          // lastValidBlockHeight: transaction.lastValidBlockHeight,
        },
        "confirmed"
      );
      if (configs.onConfirm) {
        configs.onConfirm(signature);
      }

      if (confirmation.value.err) {
        const txData = await connection.getParsedTransaction(signature, {
          maxSupportedTransactionVersion: 0,
          commitment: "confirmed",
        });

        throw {
          error: "ConfirmationError",
          logMessages: txData?.meta?.logMessages || [],
        };
      }

      console.log({ confirmation, signature });
      return signature;
    },
    [cluster, provider, connection]
  );

  return (
    <WalletContext.Provider value={{ sendTransaction }}>
      {children}
    </WalletContext.Provider>
  );
};

const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const [cluster] = useWalletStore((state: any) => [state.cluster]);
  const endpoint = useMemo(() => clusterApiUrl(cluster), [cluster]);

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new UnsafeBurnerWalletAdapter(),
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new CloverWalletAdapter(),
      new MathWalletAdapter(),
      new TrustWalletAdapter(),
      new CoinbaseWalletAdapter(),
    ],
    [cluster]
  );

  const installedWallets = useMemo(() => {
    return wallets.filter((wallet) => wallet.readyState === "Installed");
  }, [wallets]);

  return (
    <Web3Context.Provider value={{ installedWallets }}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <IrysWrapper>{children}</IrysWrapper>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Web3Context.Provider>
  );
};

export { Web3Provider, Web3Context, WalletContext };
