import { useEffect, useState, useCallback, useMemo, useContext } from "react";

import { useWallet } from "@solana/wallet-adapter-react";

import {
  DropdownMenuContent,
  DropdownMenuItemTitle,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
} from "@showtime-xyz/universal.dropdown-menu";
import { Pressable } from "@showtime-xyz/universal.pressable";
import { Text } from "@showtime-xyz/universal.text";
import { toast } from "@showtime-xyz/universal.toast";
import { View } from "@showtime-xyz/universal.view";

import { MenuItemIcon } from "app/components/dropdown/menu-item-icon";
import { FontAwesome } from "app/components/fontawesome";
import { FONT_AWESOME_ICON_SIZE } from "app/constants/layout";
import { DEVNET_CLUSTER, MAINNET_CLUSTER } from "app/constants/web3";
import { shorthandAddress } from "app/lib/utilities/wallet";
import { Link } from "app/navigation/link";
import { useNavigationStore } from "app/stores/navigation-store";
import { useWalletStore } from "app/stores/wallet-store";

import { Web3Context } from "./provider";
import { WalletsLister } from "./wallets-lister";

const buttonClass =
  "h-[50px] cursor-pointer flex-row items-center rounded-2xl ps-4 transition-all bg-pink-700 hover:bg-pink-600 focus:bg-pink-600";

const ConnectButton = ({ asIcon = false }: any) => {
  const [setModal, hideModal] = useNavigationStore((state) => [
    state.setModal,
    state.hideModal,
  ]);
  const [cluster, setCluster, storeWallet, setPublicKey] = useWalletStore(
    (state: any) => [
      state.cluster,
      state.setCluster,
      state.wallet,
      state.setPublicKey,
    ]
  );
  const { publicKey, disconnect } = useWallet();
  const { installedWallets } = useContext(Web3Context);
  const [key, setKey] = useState<number>(0);

  const clusterSwitchLabel = useMemo(() => {
    if (cluster === DEVNET_CLUSTER) {
      return MAINNET_CLUSTER;
    } else {
      return DEVNET_CLUSTER;
    }
  }, [cluster]);

  const label = useMemo(() => {
    if (publicKey) {
      return shorthandAddress(publicKey);
    } else {
      return "Connect Wallet";
    }
  }, [publicKey]);

  const switchCluster = useCallback(() => {
    setCluster(clusterSwitchLabel);
  }, [clusterSwitchLabel, setCluster]);

  const chooseWallet = useCallback(() => {
    setModal({
      component: (
        <View tw="mx-4">
          <WalletsLister
            onWalletPressed={() => {
              hideModal();
              setKey((prev) => prev + 1);
              toast.success("Wallet connected");
            }}
          />
        </View>
      ),
      title: "Choose Wallet",
    });
    setKey((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (publicKey) {
      hideModal();
      setKey((prev) => prev + 1);
      setPublicKey(publicKey);
    }
  }, [publicKey, setPublicKey]);

  if (!publicKey) {
    return (
      <View tw="flex-1">
        <Pressable tw={asIcon ? "" : buttonClass} onPress={chooseWallet}>
          <FontAwesome
            icon="fa-regular fa-wallet"
            fontSize={FONT_AWESOME_ICON_SIZE}
            color="white"
          />
          {asIcon ? null : (
            <Text tw={["font-primary-bold mx-4 text-base text-white"]}>
              Connect Wallet
            </Text>
          )}
        </Pressable>
      </View>
    );
  }

  return (
    <View tw="flex-1" key={key}>
      <DropdownMenuRoot>
        <DropdownMenuTrigger>
          <View tw={asIcon ? "" : buttonClass}>
            <FontAwesome
              icon="fa-regular fa-wallet"
              fontSize={FONT_AWESOME_ICON_SIZE}
              color="white"
            />
            {asIcon ? null : (
              <Text tw={["font-primary-bold mx-4 text-base text-white"]}>
                {label}
              </Text>
            )}
          </View>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          style={{ minWidth: 150 }}
          disableBlurEffect
          side="bottom"
          sideOffset={0}
        >
          <DropdownMenuSub>
            <Link href="/wallet-info">
              <DropdownMenuSubTrigger key="changeWallet">
                <View tw="flex-row items-center justify-between">
                  <View tw="flex-1 flex-row items-center">
                    <MenuItemIcon icon="fa-regular fa-circle-info" />
                    <DropdownMenuItemTitle tw="font-primary text-gray-700 ms-1">
                      Wallet Info
                    </DropdownMenuItemTitle>
                  </View>
                </View>
              </DropdownMenuSubTrigger>
            </Link>
            <Pressable onPress={chooseWallet}>
              <DropdownMenuSubTrigger key="changeWallet">
                <View tw="flex-row items-center justify-between">
                  <View tw="flex-1 flex-row items-center">
                    <MenuItemIcon icon="fa-regular fa-swap" />
                    <DropdownMenuItemTitle tw="font-primary text-gray-700 ms-1">
                      Change Wallet
                    </DropdownMenuItemTitle>
                  </View>
                </View>
              </DropdownMenuSubTrigger>
            </Pressable>
            {storeWallet ? (
              <Pressable onPress={switchCluster}>
                <DropdownMenuSubTrigger key="changeWallet">
                  <View tw="flex-row items-center justify-between">
                    <View tw="flex-1 flex-row items-center">
                      <MenuItemIcon icon="fa-regular fa-network-wired" />
                      <DropdownMenuItemTitle tw="font-primary text-gray-700 ms-1">
                        Switch to {clusterSwitchLabel}
                      </DropdownMenuItemTitle>
                    </View>
                  </View>
                </DropdownMenuSubTrigger>
              </Pressable>
            ) : null}
            <Pressable
              onPress={() => {
                disconnect();
                toast.error("Wallet disconnected");
              }}
            >
              <DropdownMenuSubTrigger key="disconnect">
                <View tw="flex-row items-center justify-between">
                  <View tw="flex-1 flex-row items-center">
                    <MenuItemIcon icon="fa-regular fa-power-off" />
                    <DropdownMenuItemTitle tw="font-primary text-gray-700 ms-1">
                      Disconnect
                    </DropdownMenuItemTitle>
                  </View>
                </View>
              </DropdownMenuSubTrigger>
            </Pressable>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </View>
  );
};

export { ConnectButton };
