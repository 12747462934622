var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5xvb3W8NhvsKK3-cHQ5Ck"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { ExtraErrorData, CaptureConsole, Offline } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_CLIENT_ENVIRONMENT,
  tracesSampleRate: 0.0,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [process.env.NEXT_PUBLIC_BACKEND_URL],
    }),
    new ExtraErrorData(),
    new CaptureConsole({
      levels: ["warn", "error"],
    }),
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});
