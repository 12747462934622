const MAX_CONTENT_WIDTH = 1200;
const MAX_HEADER_WIDTH = 1440;
const MOBILE_WEB_TABS_HEIGHT = 50;
const WEB_HEADER_HEIGHT = 0;
const MOBILE_WEB_HEADER_HEIGHT = 48;
const DESKTOP_CONTENT_WIDTH = 768;
const DESKTOP_LEFT_MENU_WIDTH = 263;
const FONT_AWESOME_ICON_SIZE = 20;

export {
  MAX_CONTENT_WIDTH,
  MAX_HEADER_WIDTH,
  MOBILE_WEB_TABS_HEIGHT,
  WEB_HEADER_HEIGHT,
  MOBILE_WEB_HEADER_HEIGHT,
  DESKTOP_CONTENT_WIDTH,
  DESKTOP_LEFT_MENU_WIDTH,
  FONT_AWESOME_ICON_SIZE,
};
